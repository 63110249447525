<template>
	<modal :show="blockUser != undefined" type="notice" @close="$emit('actionCancelled')">
		<template v-slot:header>
			{{ blockString }}
		</template>
		Are you sure you want to {{ blockString.toLowerCase() }} this user?
		<template v-slot:footer>
			<button class="btn btn-danger" @click.prevent="blockSelectedUser">{{ blockString }}</button>
		</template>
	</modal>
</template>

<script>
export default {
	name: 'block-dialog',
	props: {
		blockUser: {
			type: Object,
			default: undefined,
		},
	},
	data() {
		return {
		}
	},
	methods: {
		blockSelectedUser() {
			if (this.blockUser) {
				var blockUserID = this.blockUser.userID;
				var blockStatus = !this.blockUser.isBlocked;
				var blockString = this.blockString.toLowerCase();

				this.$emit('performingAction');
				this.$store.dispatch('blockUser', {
					blockUserID,
					blockStatus
				}).then(() => {
					this.$emit('actionComplete');
				}).catch((error) => {
					this.$notify({
						title: "Failed to " + blockString + " user",
						icon: 'fa fa-exclamation-triangle',
						type: 'danger',
						message: error.toString(),
					});
					this.$emit('actionFailed');
				});
			} else {
				this.$emit('actionFailed');
			}
		}
	},
	computed: {
		blockString() {
			if (this.blockUser == undefined) {
				return '';
			} else if (this.blockUser.isBlocked) {
				return 'Unblock';
			} else {
				return 'Block';
			}
		}
	}
}
</script>
<style scoped>
</style>
