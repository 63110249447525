<template>
	<modal :show="(resetUser != undefined)" type="notice" @close="resetUser == undefined; $emit('actionCancelled')">
		<template v-slot:header>
			Reset password
		</template>
		<div class="row mt-1 mb-3">
			<div class="col-4 align-self-center">
				New Password:
			</div>
			<div class="col-8">
				<input class="form-control" v-model="pass" type="password" placeholder="New password here" />
			</div>
		</div>
		<div class="row mt-1 mb-3">
			<div class="col-4 align-self-center">
				Retype Password:
			</div>
			<div class="col-8">
				<input class="form-control" v-model="data.password" type="password" placeholder="Retype password " />
			</div>
		</div>
		<template v-slot:footer>
			<button class="btn btn-danger" @click.prevent="resetPassword">Reset Password</button>
		</template>
	</modal>
</template>

<script>
export default {
	name: 'reset-password-dialog',
	props: {
		resetUser: {
			type: Object,
			default: undefined,
		},
	},
	data() {
		return {
			pass:'',
			data:{
				password:""
			},

		}
	},
	methods: {
		resetPassword() {
			if (this.resetUser) {
				var userID = this.resetUser.id;


				if (this.pass.trim() != this.data.password.trim()) {
					this.$notify({
						title: "Invalid passwords",
						icon: 'fa fa-exclamation-triangle',
						type: 'danger',
						message: "The passwords entered don't match each other",
					});
					return;
				}
				this.$emit('performingAction');
				this.$store.dispatch('resetUserPassword', {
					userID,
					data:this.data
				}).then(() => {
					this.$emit('actionComplete');
					this.data.password = '';
					this.pass=""
					this.$notify({
						title: "Password successfully changed",
						icon: 'fa fa-exclamation-triangle',
						type: 'success',
					});
				}).catch((error) => {
					this.$notify({
						title: "Failed to reset password",
						icon: 'fa fa-exclamation-triangle',
						type: 'danger',
						message: error.toString(),
					});
					this.$emit('actionFailed');
				});
			} else {
				this.$emit('actionFailed');
			}
		}
	},
}
</script>
<style scoped>
</style>
