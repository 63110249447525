<template>
	<div>
		<div class="">
			<div class="container-fluid align-items-center">
				<div class="row mt-2">
					<div class="col">
						<h1 class="display-3">Sell</h1>
					</div>
				</div>
			</div>
			<div class="my-3 px-lg-4">
				<div v-if="dashboardData.farmerProducts.length>0" class="card p-2">
					<div class="row">
						<div class="col">
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="pagedFarmerProductList"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th
											v-for="(
												header, index
											) in headers"
											:key="index"
										>
											{{ header }}
										</th>
									</template>

									<template slot-scope="{ row, index }">
										<td>
											{{
												pageSize * (currentPage - 1) +
												(index + 1)
											}}
										</td>
										<td>{{ row.user }}</td>
										<td>{{ row.name }}</td>
										<td>{{ row.address }}</td>
										<td>{{ row.quantity }}</td>
										<td>{{ row.unit }}</td>
										<td>{{ row.amount }}</td>
									</template>
								</base-table>
							</div>
						</div>
					</div>
					<base-pagination
						class="mt-3 mr-3"
						:perPage="pageSize"
						:total="dashboardData.farmerProducts.length"
						v-model="currentPage"
						align="center"
					></base-pagination>
				</div>
                <div v-else class="d-flex justify-content-center align-content-center align-items-center"><h2 class="text-warning">No Data Found</h2></div>
			</div>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	name: "ecommerce dashboard",

	props: {},
	data() {
		return {
			displayLoader: false,
			pageSize: 10,
			currentPage: 1,
			openModal:false,
			edit: {
				index: null,
				status: false
			},
			statusData: {
				id: "",
				order_status: ""
			},
			optionsList: ["pending", "on_route", "delivered", "cancelled"],
			orderDetails:{}

		};
	},
	methods: {
		goToPage(page) {
			this.$router.push(
				"/ecommerce-section/" + page.toLowerCase().replaceAll(" ", "-")
			);
		},
		fetchFarmerProductList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerProductList")
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch farmer products",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
        

	},
    computed: {
        dashboardData() {
			return this.$store.state.EcommerceData
		},
        headers() {
			return this.$store.state.EcommerceData.tableHeaders;
		},
		pagedFarmerProductList() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.dashboardData.farmerProducts.slice(start, start + this.pageSize);
		},
	},
    mounted() {
		this.fetchFarmerProductList()
    },
};
</script>
<style scoped>
.custom-image {
	height: 6rem;
	width: 6rem;
	border: 1px solid gray;
}
.custom-card {
	padding: 10px;
	margin: 10px;
	border: 1px solid #cfd2cf;
}
</style>
