<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="text-center col">
						<h1 class="display-4">Farm & Crop Profile</h1>
					</div>
				</div>

				<!-- Table 1 -->
				<div class="my-5 px-lg-4">
					<div class="shadow card">
						<div class="row">
							<h4 class="col mt-4 mb-3 text-center">
								Local Varieties
							</h4>
							<div
								class="position-absolute align-self-center right-0 pr-3"
							>
								<base-button
									v-if="token === 'admin'"
									type="button"
									class="btn-sm btn-primary"
									@click.prevent="addLocalData"
								>
									Add new
									<i class="pl-1 fa fa-plus"></i>
								</base-button>
							</div>
						</div>

						<div class="table-responsive">
							<base-table
								class="table align-items-center table-flush"
								title=""
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="localTableData"
							>
								<template slot="columns">
									<th>Sl No</th>
									<th
										v-for="(
											header, index
										) in varietyTableHeaders"
										:key="index"
									>
										{{ header }}
									</th>
									<th v-if="token === 'admin'">Actions</th>
								</template>

								<template slot-scope="{ row, index }">
									<td>{{ index + 1 }}</td>
									<td v-if="editLocalIndex != index">
										{{ row.variety_name }}
									</td>
									<td v-else>
										<base-input
											v-model="localCrop.variety_name"
											class="m-0"
										/>
									</td>
									<td v-if="editLocalIndex != index">
										{{ row.count }}
									</td>
									<td v-else>
										<base-input
											v-model="localCrop.count"
											class="m-0"
											type="number"
											min="0"
										/>
									</td>
									<td v-if="editLocalIndex != index">
										{{ row.crop_age }}
									</td>
									<td v-else>
										<base-input
											v-model="localCrop.crop_age"
											class="m-0"
											type="number"
											min="0"
										/>
									</td>
									<td v-if="editLocalIndex != index">
										{{ row.supporting_crop }}
									</td>
									<td v-else>
										<base-input
											v-model="localCrop.supporting_crop"
											class="m-0"
										/>
									</td>
									<td v-if="editLocalIndex != index">
										{{ row.crop_height }}
									</td>
									<td
										v-else
										class="d-flex justify-content-between"
									>
										<base-input
											v-model="localCrop.crop_height"
											class="m-0"
											type="number"
											min="0"
										>
											<template v-slot:addonRight>{{
												localCropUnit
											}}</template>
										</base-input>
										<select
											v-model="localCropUnit"
											style="width: 68px"
											name="unit"
											id="unit"
											class="form-control ml-1"
										>
											<option value="cm">cm</option>
											<option value="m">m</option>
										</select>
									</td>
									<td v-if="token === 'admin'">
										<i
											class="btn"
											@click.prevent="
												editLocalData(row, index)
											"
											:class="[
												editLocalIndex != index
													? 'fas fa-pen'
													: 'fa fa-check',
											]"
										></i>
										<i
											v-if="
												editLocalIndex == index &&
												row.id == undefined
											"
											@click.prevent="cancelAddLocalCrop"
											class="btn fas fa-times text-danger"
										></i>
										<i
											class="btn fas fa-trash text-danger"
											@click.prevent="
												deleteLocalCrop(index)
											"
											v-if="editLocalIndex != index"
										></i>
									</td>
								</template>
							</base-table>
						</div>
					</div>
				</div>

				<!-- Table 2 -->
				<div class="my-5 px-lg-4">
					<div class="shadow card">
						<div class="row">
							<h4 class="col mt-4 mb-3 text-center">
								Improved Varieties
							</h4>
							<div
								class="position-absolute align-self-center right-0 pr-3"
							>
								<base-button
									v-if="token === 'admin'"
									type="button"
									class="btn-sm btn-primary"
									@click.prevent="addImprovedData"
								>
									Add new
									<i class="pl-1 fa fa-plus"></i>
								</base-button>
							</div>
						</div>

						<div class="table-responsive">
							<base-table
								class="table align-items-center table-flush"
								title=""
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="improvedTableData"
							>
								<template slot="columns">
									<th>Sl No</th>
									<th
										v-for="(
											header, index
										) in varietyTableHeaders"
										:key="index"
									>
										{{ header }}
									</th>
									<th v-if="token === 'admin'">Actions</th>
								</template>

								<template slot-scope="{ row, index }">
									<td>{{ index + 1 }}</td>
									<td v-if="editImprovedIndex != index">
										{{ row.variety_name }}
									</td>
									<td v-else>
										<base-input
											v-model="improvedCrop.variety_name"
											class="m-0"
										/>
									</td>
									<td v-if="editImprovedIndex != index">
										{{ row.count }}
									</td>
									<td v-else>
										<base-input
											v-model="improvedCrop.count"
											class="m-0"
											type="number"
											min="0"
										/>
									</td>
									<td v-if="editImprovedIndex != index">
										{{ row.crop_age }}
									</td>
									<td v-else>
										<base-input
											v-model="improvedCrop.crop_age"
											class="m-0"
											type="number"
											min="0"
										/>
									</td>
									<td v-if="editImprovedIndex != index">
										{{ row.supporting_crop }}
									</td>
									<td v-else>
										<base-input
											v-model="
												improvedCrop.supporting_crop
											"
											class="m-0"
										/>
									</td>
									<td v-if="editImprovedIndex != index">
										{{ row.crop_height }}
									</td>
									<td
										v-else
										class="d-flex justify-content-between"
									>
										<base-input
											v-model="improvedCrop.crop_height"
											class="m-0"
											type="number"
											min="0"
										>
											<template v-slot:addonRight>{{
												improvedCropUnit
											}}</template>
										</base-input>
										<select
											v-model="improvedCropUnit"
											style="width: 68px"
											name="unit"
											id="impUnit"
											class="form-control ml-1"
										>
											<option value="cm">cm</option>
											<option value="m">m</option>
										</select>
									</td>
									<td v-if="token === 'admin'">
										<i
											class="btn"
											@click.prevent="
												editImprovedData(row, index)
											"
											:class="[
												editImprovedIndex != index
													? 'fas fa-pen'
													: 'fa fa-check',
											]"
										></i>
										<i
											v-if="
												editImprovedIndex == index &&
												row.id == undefined
											"
											@click.prevent="
												cancelAddImprovedCrop
											"
											class="btn fas fa-times text-danger"
										></i>
										<i
											class="btn fas fa-trash text-danger"
											@click.prevent="
												deleteImprovedCrop(index)
											"
											v-if="editImprovedIndex != index"
										></i>
									</td>
								</template>
							</base-table>
						</div>
					</div>
				</div>

				<div class="my-5 row">
					<div class="col text-center">
						<h4 class="display-4">Soil Report</h4>
					</div>
				</div>

				<div class="col">
					<div class="shadow card">
						<div class="table-responsive">
							<base-table
								class="table align-items-center table-flush"
								title=""
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="microTableData"
							>
								<template slot="columns">
									<th>Sl no.</th>
									<th
										v-for="(
											header, index
										) in microTableHeaders"
										:key="index"
									>
										{{ header }}
									</th>
									<th v-if="token === 'admin'">Actions</th>
								</template>
								<template slot-scope="{ row, index }">
									<td>{{ index + 1 }}</td>
									<td>{{ row.indicator }}</td>
									<td v-if="editSoilDataIndex != index">
										{{ row.quantity }}
									</td>
									<td v-else>
										<input
											type="number"
											v-model="soilData.quantity"
											min="0"
											class="form-control-alternative p-2 border"
										/>
									</td>
									<td>{{ row.behaviour }}</td>
									<td v-if="token === 'admin'">
										<i
											class="btn"
											@click.prevent="
												editSoilData(index, row)
											"
											:class="[
												editSoilDataIndex != index
													? ' fas fa-pen'
													: 'fa fa-check',
											]"
										></i>
									</td>
								</template>
							</base-table>
						</div>
					</div>
				</div>

				<!-- <div class="col mt-5">
					<div class="shadow card">
						<div class="row">
							<h4 class="col mt-4 mb-3 text-center">
								Intercroppings
							</h4>
							<div
								class="
									position-absolute
									align-self-center
									right-0
									pr-3
								"
							>
								<base-button
									type="button"
									class="btn-sm btn-primary"
									@click.prevent="
										displayDialog(
											intercropTableHeaders[0],
											'',
											'intercrop',
											-1,
											false
										)
									"
								>
									Add new
									<i class="pl-1 fa fa-plus"></i>
								</base-button>
							</div>
						</div>

						<div class="table-responsive">
							<base-table
								class="table align-items-center table-flush"
								title=""
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="intercropTableData"
							>
								<template slot="columns">
									<th>Sl No</th>
									<th
										v-for="(
											header, index
										) in intercropTableHeaders"
										:key="index"
									>
										{{ header }}
									</th>
									<th>Actions</th>
								</template>
								<template slot-scope="{ row, index }">
									<td>{{ index + 1 }}</td>
									<td>{{ row.inter_crop }}</td>
									<td>
										<i
											class="btn fas fa-pen"
											@click.prevent="
												displayDialog(
													intercropTableHeaders[0],
													intercropTableData[index],
													'intercrop',
													index,
													false
												)
											"
										></i>
										<i
											class="btn fas fa-trash text-danger"
											@click.prevent="
												deleteIntercrop(index)
											"
										></i>
									</td>
								</template>
							</base-table>
						</div>
					</div>
				</div> -->
			</div>

			<!-- Table -->
			<div class="row">
				<div class="col-12 my-3 px-lg-5">
					<div class="shadow card">
						<h4 class="mt-4 mb--3 text-center">
							Miscellaneous Farm Data
						</h4>

						<tabs
							fill
							class="p-4 flex-column flex-md-row"
							v-model="selectedDataTableIndex"
						>
							<tab-pane
								v-for="(tableData, index) in this
									.farmProfileData.dataTables"
								:key="index"
								:title="tableData.name"
							>
							</tab-pane>

							<tab-pane
								title="Upload Files"
								v-if="token === 'admin'"
							>
								<div class="p-4 m-2">
									<label class="form-control-label"
										>Choose section</label
									>
									<select
										v-model="miscFile.section"
										class="form-group form-control"
									>
										<option value="water_info">
											Water Info
										</option>
										<option value="planting_methods">
											Planting Methods
										</option>
										<option value="farm_practices">
											Farm Practices
										</option>
										<option value="soil_and_land_info">
											Soil and Land Info
										</option>
										<option value="meteorological_data">
											Mateorogical Data
										</option>
										<option value="satellite_data">
											Satellite Data
										</option>
									</select>
									<label class="form-control-label"
										>Choose the file to upload</label
									>
									<input
										type="file"
										class="form-control form-group"
										@change="onFileChange"
									/>
									<base-button
										type="success"
										class=""
										@click.prevent="uploadMiscFile"
										>Save</base-button
									>
								</div>
							</tab-pane>
						</tabs>

						<div class="p-4 m-2" v-if="selectedDataTableIndex < 4">
							<label class="form-control-label text-black" for=""
								>Details</label
							>
							<textarea
								:disabled="!edit"
								class="form-control mt-3"
								v-model="miscData.details"
								name=""
								id=""
								cols="30"
								rows="10"
							></textarea>
							<button
								v-if="edit === false && token === 'admin'"
								@click.prevent="editData"
								class="btn btn-primary float-right mt-4"
							>
								Edit
							</button>
							<div v-if="edit" class="float-right">
								<button
									@click.prevent="edit = false"
									class="btn btn-danger mt-4"
								>
									Cancel
								</button>
								<button
									class="btn btn-success mt-4"
									@click.prevent="saveMiscData"
								>
									Save
								</button>
							</div>
							<h2 class="mt-7">Miscellaneous File </h2>
							<div v-if="imageFiles.length>0">
							<div class="d-flex justify-content-start align-content-center align-items-center" v-for="(item, key) in imageFiles" :key="key">
							<div class="card  p-1 px-4 shadow-lg border-1 mb-2">
								<h3 class="mt-2">
									<a :href="item.file" target="_blank"
										><i class="fas fa-file mr-1"></i> View File</a
									>
								</h3>
							</div>
								
								<div class="pl-5" style="cursor:pointer" @click.prevent="deleteImage(selectedDataTableIndex,key)">
									<i class="fas fa-trash text-danger ml-5"></i>
									
								</div>
							</div>
							</div>
							<div v-else>
								<h3 class="text-warning mt-3 ml-1">No Files Found</h3>
							</div>
						</div>
						<!-- v-if because we don't want the table for the "Upload File" section -->
						<div
							class="table-responsive"
							v-if="selectedDataTableIndex === 4"
						>
							<base-table
								class="table align-items-center table-flush"
								title=""
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="dataTable.data"
							>
								<template slot="columns">
									<th>Sl No</th>
									<th
										v-for="(
											header, index
										) in dataTable.headers"
										:key="index"
									>
										{{ header }}
									</th>
									<th v-if="token === 'admin'">Actions</th>
								</template>

								<template slot-scope="{ row, index }">
									<td>{{ index + 1 }}</td>
									<td>{{ row.indicator }}</td>
									<td>{{ row.details }}</td>

									<td v-if="token === 'admin'">
										<i
											class="btn fas fa-pen"
											@click.prevent="
												displayDialog(
													dataTable.data[index]
														.indicator,
													dataTable.data[index],
													'dataTable',
													index,
													false
												)
											"
										></i>
									</td>
								</template>
							</base-table>
							<h2 class="mt-7 p-2 ml-3">Miscellaneous File</h2>
							<div v-if="imageFiles.length>0" >
							<div class="p-2 ml-3 d-flex justify-content-start align-content-center align-items-center" v-for="(item, key) in imageFiles" :key="key">
							<div class="card  p-1 px-4 shadow-lg border-1 mb-2">
								<h3 class="mt-2">
									<a :href="item.file" target="_blank"
										><i class="fas fa-file mr-1"></i>View File</a
									>
								</h3>
								</div>
								<div class="pl-5" style="cursor:pointer" @click.prevent="deleteImage(selectedDataTableIndex,key)">
									<i class="fas fa-trash text-danger ml-5"></i>
									
								</div>								
							</div>
							</div>
							<div v-else>
								<h3 class="text-warning mt-3 ml-4">No files Found</h3>
							</div>
							
						</div>
					</div>
				</div>
			</div>

			<modal :show="dialog.showDialog" @close="dialog.showDialog = false">
				<template v-slot:header>
					{{ dialog.heading }}
				</template>
				<div class="row align-items-center">
					<div class="col-12">
						<textarea
							class="form-control my-0"
							rows="10"
							v-model="dialog.data"
							:disabled="dialog.viewOnly"
						></textarea>
					</div>
				</div>
				<template v-slot:footer>
					<button
						class="btn btn-danger"
						@click.prevent="resetDialogData()"
						v-if="!dialog.viewOnly"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="applyDialogData()"
					>
						Done
					</button>
				</template>
			</modal>
			<delete-dialog
				:data="deleteTableData"
				v-model="deleteItemIndex"
				:actionString="deleteItemString"
				:item="item"
				:miscData="{ farmerID: farmerID }"
			>
				<span slot="warning">This data will be removed!</span>
			</delete-dialog>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";

export default {
	name: "farm-profile",
	components: {
		DeleteDialog,
	},
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		},
	},
	data() {
		return {
			edit: false,
			landChange: false,
			displayLoader: false,
			localCropUnit: "cm",
			improvedCropUnit: "cm",
			dialog: {
				showDialog: false,
				heading: "Heading",
				itemID: -1,
				data: "",
				viewOnly: true,
				targetVar: undefined,
				targetIndex: -1,
			},
			cropData: {
				inter_crop: "",
				land: 0,
			},
			soilData: {
				quantity: 0,
			},
			localCrop: {
				variety_name: "",
				count: null,
				crop_age: 0,
				supporting_crop: "",
				crop_height: 0,
				is_improved_variety: false,
				land_id: null,
				crop_id: null,
			},
			improvedCrop: {
				variety_name: "",
				count: null,
				crop_age: 0,
				supporting_crop: "",
				crop_height: 0,
				is_improved_variety: true,
				land_id: null,
				crop_id: null,
			},
			miscData: {
				details: "",
			},
			satelliteData: {
				details: "",
			},
			miscFile: {
				section: "",
				file: null,
				land: null,
				crop: null,
			},
			item: "",
			improvedCropID: null,
			localCropID: null,
			deleteTableData: [],
			deleteItemIndex: -1,
			deleteItemString: "",
			editLocalIndex: -1,
			editImprovedIndex: -1,
			editSoilDataIndex: -1,
			soilDataID: null,
			selectedDataTableIndex: 0,
			files: [],
			imageFiles: [],
		};
	},
	methods: {
		editData() {
			this.edit = true;
			this.fetchMiscellaneousDetails();
		},
		displayDialog(header, item, targetVar, targetIndex, viewOnly) {
			this.dialog.heading = header;
			this.dialog.targetIndex = targetIndex;
			this.dialog.itemID = item.id;
			this.dialog.targetVar = targetVar;
			if (
				this.dialog.itemID != undefined &&
				this.dialog.targetVar == "dataTable"
			) {
				this.fetchSatelliteDetails();
				this.satelliteData = item;
				this.dialog.data = this.satelliteData.details;
			}
			this.dialog.viewOnly = viewOnly;
			this.dialog.showDialog = true;
		},
		applyDialogData() {
			if (!this.dialog.viewOnly) {
				if (this.dialog.targetVar == "dataTable") {
					// Edit existing dataTable entry
					this.saveSatelliteData();
					this.resetDialogData();
				}
			}
		},
		deleteImage(selectedDataTableIndex, index) {
			this.deleteTableData = this.imageFiles;
			this.deleteItemIndex = index;
			if (selectedDataTableIndex === 1 || selectedDataTableIndex === 2) {
				this.deleteItemString = "deleteCropMiscFile"
			}
			if (selectedDataTableIndex === 0 || selectedDataTableIndex === 3) {
				this.deleteItemString = "deleteLandMiscFile"
			}
			if (selectedDataTableIndex === 4 ) {
				this.deleteItemString = "deleteSatelliteFile"
			}
			
			this.item="Miscellaneous file"
		},
		deleteImprovedCrop(index) {
			this.deleteTableData = this.improvedTableData;
			this.deleteItemIndex = index;
			this.deleteItemString = "deleteImprovedCrop";
			this.item = "Improved variety Data";
		},
		// deleteIntercrop(index) {
		// 	this.deleteTableData = this.intercropTableData;
		// 	this.deleteItemIndex = index;
		// 	this.deleteItemString = "deleteInterCropping";
		// 	this.item = "Intercropping Data";
		// },
		deleteLocalCrop(index) {
			this.deleteTableData = this.localTableData;
			this.deleteItemIndex = index;
			this.deleteItemString = "deleteLocalCrop";
			this.item = "Local variety Data";
		},
		addLocalData() {
			this.localCrop.variety_name = "";
			this.localCrop.count = null;
			this.localCrop.crop_age = 0;
			this.localCrop.supporting_crop = "";
			this.localCrop.crop_height = 0;
			this.localCrop.id = undefined;

			this.localTableData.unshift(this.localCrop);
			this.editLocalIndex = -1; // Reset existing editing rows if any
			this.editLocalIndex = 0; // Enable edit the first row
		},
		cancelAddLocalCrop() {
			this.editLocalIndex = -1;
			this.localTableData.shift(this.localCrop);
		},
		cancelAddImprovedCrop() {
			this.editImprovedIndex = -1;
			this.improvedTableData.shift(this.improvedCrop);
		},
		editLocalData(row, index) {
			this.localCropID = row.id;
			if (this.editLocalIndex == index) {
				this.saveLocalVariety();
			} else {
				if (this.localCropID != null) {
					this.fetchLocalVarietyDetails();
					this.localCrop = this.currentLocalVarietyItem;
					this.editLocalIndex = index;
				}
			}
		},
		addImprovedData() {
			this.improvedCrop.variety_name = "";
			this.improvedCrop.count = null;
			this.improvedCrop.crop_age = 0;
			this.improvedCrop.supporting_crop = "";
			this.improvedCrop.crop_height = 0;
			this.localCrop.id = undefined;

			this.improvedTableData.unshift(this.improvedCrop);
			this.editImprovedIndex = -1; // Reset existing editing rows if any
			this.editImprovedIndex = 0; // Enable edit the first row
		},
		editImprovedData(row, index) {
			this.improvedCropID = row.id;
			if (this.editImprovedIndex == index) {
				this.performAction();
				this.editImprovedIndex = -1;
			} else {
				if (this.improvedCropID != null) {
					this.fetchImprovedVarietyDetails();
					this.improvedCrop = this.currentItem;
					this.editImprovedIndex = index;
				}
			}
		},
		editSoilData(index, row) {
			this.soilDataID = row.id;
			if (this.editSoilDataIndex == index) {
				this.saveSoilData();
			} else {
				this.displayLoader = true;
				this.$store
					.dispatch("fetchSoilReportDetails", {
						farmerID: this.farmerID,
						itemID: this.soilDataID,
					})
					.then(() => {
						this.displayLoader = false;
						this.soilData = this.selectedSoilData;
					})
					.catch((error) => {
						this.$notify({
							title: "Failed to fetch soil report details",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
				this.editSoilDataIndex = index;
			}
		},
		resetDialogData() {
			this.dialog.showDialog = false;
			this.dialog.heading = "";
			this.dialog.targetVar = undefined;
			this.dialog.data = "";
			this.dialog.viewOnly = false;
		},
		// Local variety Action
		localVarietyInputHasErrors() {
			var invalidValue = false;
			if (this.localCrop.variety_name == "") {
				invalidValue = true;
			}
			if (this.localCrop.count == 0) {
				invalidValue = true;
			}
			if (this.localCrop.crop_age == 0) {
				invalidValue = true;
			}
			if (this.localCrop.supporting_crop == "") {
				invalidValue = true;
			}
			if (this.localCrop.crop_height == "") {
				invalidValue = true;
			}
			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}

			return invalidValue;
		},
		saveLocalVariety() {
			if (this.localVarietyInputHasErrors()) {
				return;
			}
			this.localCrop.land_id = this.selectedLandID;
			this.localCrop.crop_id = this.selectedCrop;
			var actionString = "add";
			var actionObject = {
				localCrop: this.localCrop,
			};
			if (this.localCrop.id != undefined) {
				// Edit existing action params
				actionString = "edit";
				actionObject = {
					localCrop: this.localCrop,
				};
			}
			this.displayLoader = true;
			this.$store
				.dispatch("updateLocalVarietyDetails", {
					farmerID: this.farmerID,
					actionObject: actionObject,
				})
				.then(() => {
					this.displayLoader = false;
					this.fetchLocalVarietiesList();
					this.editLocalIndex = -1;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title:
							"Failed to " + actionString + " local crop details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		// Improvedcrop Action
		inputHasErrors() {
			var invalidValue = false;
			if (this.improvedCrop.variety_name == "") {
				invalidValue = true;
			}
			if (this.improvedCrop.count == 0) {
				invalidValue = true;
			}
			if (this.improvedCrop.crop_age == 0) {
				invalidValue = true;
			}
			if (this.improvedCrop.supporting_crop == "") {
				invalidValue = true;
			}
			if (this.improvedCrop.crop_height == "") {
				invalidValue = true;
			}
			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}

			return invalidValue;
		},
		performAction() {
			if (this.inputHasErrors()) {
				return;
			}
			this.displayLoader = true;
			this.improvedCrop.land_id = this.selectedLandID;
			this.improvedCrop.crop_id = this.selectedCrop;
			// Add new action params
			var actionString = "add";
			var actionObject = {
				improvedCrop: this.improvedCrop,
			};
			if (this.improvedCrop.id != undefined) {
				// Edit existing action params
				actionString = "edit";
				actionObject = {
					improvedCrop: this.improvedCrop,
				};
			}
			this.$store
				.dispatch("updateImprovedVarietyDetails", {
					farmerID: this.farmerID,
					actionObject: actionObject,
				})
				.then(() => {
					this.displayLoader = false;
					this.fetchImprovedVarietiesList();
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title:
							"Failed to " +
							actionString +
							" improved crop details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},

		// Intercropping Action
		// saveDialogData() {
		// 	this.cropData.inter_crop = this.dialog.data;
		// 	this.cropData.land=this.selectedLandID;
		// 	var invalidValue = false;
		// 	if (this.cropData.inter_crop == "") {
		// 		invalidValue = true;
		// 	}
		// 	if (this.cropData.land == 0) {
		// 		invalidValue = true;
		// 	}
		// 	if (invalidValue) {
		// 		this.$notify({
		// 			title: "Error",
		// 			icon: "fa fa-exclamation-triangle",
		// 			type: "danger",
		// 			message: "Please enter valid data for all fields",
		// 		});
		// 	}
		// 	this.displayLoader = true;
		// 	// Add new action params
		// 	var actionString = "add";
		// 	var actionObject = {
		// 		interCropData: this.cropData,
		// 	};

		// 	if (this.cropData.id != undefined) {
		// 		// Edit existing action params
		// 		actionString = "edit";
		// 		actionObject = {
		// 			interCropData: this.cropData,
		// 		};
		// 	}
		// 	this.$store
		// 		.dispatch("updateIntercroppingDetails", {
		// 			farmerID: this.farmerID,
		// 			actionObject: actionObject,
		// 		})
		// 		.then(() => {
		// 			this.displayLoader = false;
		// 			this.fetchImprovedVarietiesList();
		// 		})
		// 		.catch((error) => {
		// 			this.displayLoader = false;
		// 			this.$notify({
		// 				title:
		// 					"Failed to " +
		// 					actionString +
		// 					" intercropping details",
		// 				icon: "fa fa-exclamation-triangle",
		// 				type: "danger",
		// 				message: error.toString(),
		// 			});
		// 		});
		// },

		// Soil Data Action
		saveSoilData() {
			var invalidValue = false;
			if (this.soilData.quality == "") {
				invalidValue = true;
			}
			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}
			if (this.soilData.id != undefined) {
				// Edit existing action params
				var actionString = "edit";
				var actionObject = {
					soilData: this.soilData,
				};
			}
			this.displayLoader = true;
			this.$store
				.dispatch("updateSoilReportDetails", {
					farmerID: this.farmerID,
					actionObject: actionObject,
				})
				.then(() => {
					this.displayLoader = false;
					this.fetchSoilReportList();
					this.editSoilDataIndex = -1;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to" + actionString + " soil details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		// setBehavior() {
		// 	for (var i = 0; i < this.microTableData.length; i++) {
		// 		var item  = this.microTableData[i];
		// 		var val = item.quantity;
		// 		if (val >= item.high) {
		// 			item.behaviour = item.highText;
		// 		} else if (val <= item.low) {
		// 			item.behaviour = item.lowText;
		// 		} else {
		// 			item.behaviour = item.avgText;
		// 		}
		// 	}
		// },

		// fetch Tabale data and details actions

		// fetchIntercroppingList() {
		// 	this.displayLoader = true;
		// 	this.$store
		// 		.dispatch("fetchIntercroppingList", this.farmerID)
		// 		.then(()=>{
		// 			this.displayLoader = false;
		// 		})
		// 		.catch((error) => {
		// 			this.$notify({
		// 				title: "Failed to fetch intercroppings",
		// 				icon: "fa fa-exclamation-triangle",
		// 				type: "danger",
		// 				message: error.toString(),
		// 			});
		// 			this.displayLoader = false;
		// 		});
		// },
		fetchLocalVarietiesList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchLocalVarietiesList", {
					farmerID: this.farmerID,
					crop: this.selectedCrop,
					land: this.selectedLandID,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch local varieties",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		fetchImprovedVarietiesList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchImprovedVarietiesList", {
					farmerID: this.farmerID,
					crop: this.selectedCrop,
					land: this.selectedLandID,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch improved varieties",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		fetchImprovedVarietyDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchImprovedVarietyDetails", {
					farmerID: this.farmerID,
					itemID: this.improvedCropID,
				})
				.then(() => {
					this.displayLoader = false;
					this.improvedCrop = JSON.parse(
						JSON.stringify(this.currentItem)
					);
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch improved variety details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		// fetchIntercroppingDetails() {
		// 	this.displayLoader = true;
		// 	this.$store
		// 		.dispatch("fetchIntercroppingDetails", {
		// 			farmerID: this.farmerID,
		// 			itemID: this.dialog.itemID,
		// 		})
		// 		.then(() => {
		// 			this.displayLoader = false;
		// 		})
		// 		.catch((error) => {
		// 			this.$notify({
		// 				title: "Failed to fetch intercropping details",
		// 				icon: "fa fa-exclamation-triangle",
		// 				type: "danger",
		// 				message: error.toString(),
		// 			});
		// 			this.displayLoader = false;
		// 		});
		// },
		fetchLocalVarietyDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchLocalVarietyDetails", {
					farmerID: this.farmerID,
					itemID: this.localCropID,
				})
				.then(() => {
					this.displayLoader = false;
					this.localCrop = JSON.parse(
						JSON.stringify(this.currentLocalVarietyItem)
					);
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch local variety details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchSoilReportList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchSoilReportList", {
					farmerID: this.farmerID,
					land: this.selectedLandID,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch soil report",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchMiscellaneousList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchMiscellaneousList", {
					farmerID: this.farmerID,
					tableIndex: this.selectedDataTableIndex,
					crop: this.selectedCrop,
					land: this.selectedLandID,
				})
				.then(() => {
					this.displayLoader = false;
					this.miscData =
						this.farmProfileData.dataTables[
							this.selectedDataTableIndex
						].data[0];
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch miscellaneous data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchSatelliteData() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchSatelliteData", {
					farmerID: this.farmerID,
					tableIndex: this.selectedDataTableIndex,
					land: this.selectedLandID,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch satellite data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchMiscellaneousDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchMiscellaneousDetails", {
					farmerID: this.farmerID,
					itemID: this.miscData.id,
					index: this.selectedDataTableIndex,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch Miscellaneous details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		fetchSatelliteDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchSatelliteDetails", {
					farmerID: this.farmerID,
					itemID: this.miscData.id,
					index: this.selectedDataTableIndex,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch Satellite details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		fetchMiscellaneousCropFile() {
			var dataType = "planting_methods";
			if (this.selectedDataTableIndex === 2) {
				dataType = "farm_practices";
			}
			this.displayLoader = true;
			this.$store
				.dispatch("fetchMiscellaneousCropFile", {
					farmerID: this.farmerID,
					dataType: dataType,
				})
				.then(() => {
					this.displayLoader = false;
					this.imageFiles = [];
					this.imageFiles =
						this.$store.state.farmerData.farmProfileData.miscCropFiles;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch Miscellaneous crop file",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		fetchSatelliteFile() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchSatelliteFile", this.farmerID)
				.then(() => {
					this.displayLoader = false;
					this.imageFiles = [];
					this.imageFiles =
						this.$store.state.farmerData.farmProfileData.satelliteImageFile;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch Satellite file",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		fetchMiscellaneousLandFile() {
			var dataType = "water_info";
			if (this.selectedDataTableIndex === 3) {
				dataType = "soil_and_land_info";
			}
			this.displayLoader = true;
			this.$store
				.dispatch("fetchMiscellaneousLandFile", {
					farmerID: this.farmerID,
					dataType: dataType,
				})
				.then(() => {
					this.displayLoader = false;
					this.imageFiles = [];
					this.imageFiles =
						this.$store.state.farmerData.farmProfileData.miscLandFiles;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch Miscellaneous land file",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		saveMiscData() {
			var invalidValue = false;
			if (this.miscData.details == "") {
				invalidValue = true;
			}
			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}
			this.displayLoader = true;

			if (this.miscData.id != undefined) {
				// Edit existing action params
				var actionString = "edit";
			}
			this.$store
				.dispatch("updateMiscellaneousDetails", {
					farmerID: this.farmerID,
					actionObject: this.miscData,
					index: this.selectedDataTableIndex,
				})
				.then(() => {
					this.displayLoader = false;
					this.fetchMiscellaneousList();
					this.edit = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title:
							"Failed to " + actionString + " miscellaneous data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		saveSatelliteData() {
			this.satelliteData.details = this.dialog.data;
			var invalidValue = false;
			if (this.satelliteData.details == "") {
				invalidValue = true;
			}
			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}
			this.displayLoader = true;

			if (this.satelliteData.id != undefined) {
				// Edit existing action params
				var actionString = "edit";
			}
			this.$store
				.dispatch("updateSatelliteDetails", {
					farmerID: this.farmerID,
					actionObject: this.satelliteData,
					index: this.selectedDataTableIndex,
				})
				.then(() => {
					this.displayLoader = false;
					this.fetchSatelliteData();
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to " + actionString + " satellite data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.miscFile.file = this.files[0];
		},
		uploadMiscFile() {
			var formData = new FormData();
			formData.append("file", this.files[0]);
			formData.append("section", this.miscFile.section);
			if (
				this.miscFile.section == "soil_and_land_info" ||
				this.miscFile.section == "water_info"
			) {
				formData.append("land", this.selectedLandID);
			} else if (this.miscFile.section === "satellite_data") {
				formData.append("land", this.selectedLandID);
			} else {
				formData.append("land_id", this.selectedLandID);
				formData.append("crop_id", this.selectedCrop);
			}

			this.displayLoader = true;
			if (
				this.miscFile.section == "soil_and_land_info" ||
				this.miscFile.section == "water_info"
			) {
				this.$store
					.dispatch("uploadLandMiscFile", { miscFile: formData })
					.then(() => {
						this.displayLoader = false;
						this.$notify({
							title: "File successfully uploaded",
							icon: "fa fa-exclamation-triangle",
							type: "success",
						});
						this.selectedDataTableIndex = 0;
					})
					.catch((error) => {
						this.$notify({
							title: "Failed to upload Miscellaneous file",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
						this.displayLoader = false;
					});
			} else if (this.miscFile.section === "satellite_data") {
				this.$store
					.dispatch("uploadSatelliteFile", { miscFile: formData })
					.then(() => {
						this.displayLoader = false;
						this.$notify({
							title: "File successfully uploaded",
							icon: "fa fa-exclamation-triangle",
							type: "success",
						});
						this.selectedDataTableIndex = 0;
					})
					.catch((error) => {
						this.$notify({
							title: "Failed to upload Satellite file",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
						this.displayLoader = false;
					});
			} else {
				this.$store
					.dispatch("uploadCropMiscFile", { miscFile: formData })
					.then(() => {
						this.displayLoader = false;
						this.$notify({
							title: "File successfully uploaded",
							icon: "fa fa-exclamation-triangle",
							type: "success",
						});
						this.selectedDataTableIndex = 0;
					})
					.catch((error) => {
						this.$notify({
							title: "Failed to upload Miscellaneous file",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
						this.displayLoader = false;
					});
			}
		},
		viewImage() {
			alert("Hello");
		},
		// onChange(e){
		// 	this.localCropUnit=e.target.value;
		// }
	},

	computed: {
		farmProfileData() {
			return this.$store.state.farmerData.farmProfileData;
		},

		// Variety
		varietyTableHeaders() {
			return this.farmProfileData.varietyTableHeaders;
		},
		localTableData() {
			return this.farmProfileData.localTableData;
		},
		improvedTableData() {
			return this.farmProfileData.improvedTableData;
		},

		// Micro
		microTableHeaders() {
			return this.farmProfileData.microTableHeaders;
		},
		microTableData() {
			return this.farmProfileData.microTableData;
		},

		// Intercrop
		// intercropTableHeaders() {
		// 	return this.farmProfileData.intercropTableHeaders;
		// },
		// intercropTableData() {
		// 	return this.farmProfileData.intercropTableData;
		// },

		// Misc farm profile data tables
		dataTable() {
			return this.farmProfileData.dataTables[this.selectedDataTableIndex];
		},
		currentItem() {
			return this.improvedTableData.find(
				(item) => item.id == this.improvedCropID
			);
		},
		currentLocalVarietyItem() {
			return this.localTableData.find(
				(item) => item.id == this.localCropID
			);
		},
		selectedSoilData() {
			return this.microTableData.find(
				(item) => item.id == this.soilDataID
			);
		},
		token() {
			return localStorage.getItem("decoded-token");
		},
	},
	watch: {
		selectedDataTableIndex() {
			if (this.selectedDataTableIndex < 4) {
				this.fetchMiscellaneousList();
			} else if (this.selectedDataTableIndex === 4) {
				this.fetchSatelliteData();
			}
			if (
				this.selectedDataTableIndex === 1 ||
				this.selectedDataTableIndex === 2
			) {
				this.fetchMiscellaneousCropFile();
			}
			if (
				this.selectedDataTableIndex === 0 ||
				this.selectedDataTableIndex === 3
			) {
				this.fetchMiscellaneousLandFile();
			}
			if (this.selectedDataTableIndex === 4) {
				this.fetchSatelliteFile();
			}
		},

		selectedLandID() {
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
			if (this.selectedCrop != undefined) {
				this.fetchLocalVarietiesList();
				this.fetchImprovedVarietiesList();
				this.fetchSoilReportList();
				if (this.selectedDataTableIndex < 4) {
					this.fetchMiscellaneousList();
				}
				if (this.selectedDataTableIndex === 4) {
					this.fetchSatelliteData();
				}
			}
		},
		selectedCrop() {
			this.fetchLocalVarietiesList();
			this.fetchImprovedVarietiesList();
			this.fetchSoilReportList();
			if (this.selectedDataTableIndex < 4) {
				this.fetchMiscellaneousList();
			}
		},
	},
	mounted() {
		if (
			this.selectedDataTableIndex === 1 ||
			this.selectedDataTableIndex === 2
		) {
			this.fetchMiscellaneousCropFile();
		}
		if (
			this.selectedDataTableIndex === 0 ||
			this.selectedDataTableIndex === 3
		) {
			this.fetchMiscellaneousLandFile();
		}
		this.$store
			.dispatch("fetchFarmerland", this.farmerID)
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch Land",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		if (this.selectedLandID != undefined) {
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		}
		if (this.selectedCrop != undefined) {
			this.fetchLocalVarietiesList();
			this.fetchImprovedVarietiesList();
			this.fetchSoilReportList();
			this.fetchMiscellaneousList();
		}
	},
};
</script>
<style scoped></style>
