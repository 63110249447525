<template>
<div>
	<base-header class="header pt-6 pb-5 d-flex align-items-center">
		<!-- Mask -->
		<span class="mask bg-gradient-success opacity-8"></span>
		<!-- Header container -->
		<div class="container-fluid d-flex align-items-center">
			<div class="row">
				<div class="col-sm-12">
					<!-- <h1 class="display-3 text-white">Farmer Dashboard</h1>
					<p class="text-white mt-0 mb-0">Manage all farmer data here</p> -->
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt-3">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-8">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb bg-transparent">
								<li v-for="(breadcrumb, index) in getBreadCrumbs" :key="index" class="breadcrumb-item">
									<a @click.prevent="$router.push(breadcrumb.path)" href="" v-if="$route.path != breadcrumb.path"> {{breadcrumb.name }} </a>
									<span v-else class="text-muted"> {{ breadcrumb.name }} </span>
								</li>
							</ol>
						</nav>
					</div>
					<div class="col-4 text-right" v-if="getBreadCrumbs.length > 0&&$route.path !='/farmers-section/add-farmer'&&$route.name !='edit farmer profile'&&$route.name !='fvr-feedback'&&$route.name !='add fvr'&&$route.name !='edit fvr'&&$route.name !='add feedback'&&$route.name !='edit feedback'&&$route.name !='Farmer profile images'" >
						<base-dropdown :title="selectedLand ? selectedLand.name : 'Unknown land'" position="right">
							<template v-slot:title>
								<base-button type="secondary" class="dropdown-toggle">
									{{ selectedLand ? selectedLand.name : "Unknown land" }}
								</base-button>
							</template>
							<div v-for="(land, index) in landList" :key="index" @click="selectedLand = land" class="dropdown-item">
								{{ land.name }}
							</div>
						</base-dropdown>
						<base-dropdown  :title="selectedCrop?selectedCrop.crop_name:'Unknown crop'" position="right">
							<template v-slot:title>
								<base-button type="secondary" class="dropdown-toggle">
									{{ selectedCrop ? selectedCrop.crop_name:'Unknown crop' }}
								</base-button>
							</template>
							<div v-for="(crop, index) in cropList" :key="index" @click="selectedCrop = crop" class="dropdown-item">
								{{crop.crop_name }}
							</div>
						</base-dropdown>
					</div>
				</div>
				<FadeTransition :duration="200" mode="out-in">
					<router-view :selectedLandID="selectedLand ? selectedLand.id : undefined" :selectedCrop="selectedCrop ? selectedCrop.crop_id:undefined" />
				</FadeTransition>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import {
	FadeTransition
} from 'vue2-transitions';

export default {
	name: 'farmers-section',
	components: {
		FadeTransition
	},
	data() {
		return {
			selectedCrop:this.$store.state.farmerData.farmerCrops[0],
			selectedLand: this.$store.state.farmerData.farmerLands[0],
		}
	},
	methods: {
		capitalizeWord(word) {
			return word[0].toUpperCase() + word.slice(1);
		}
	},
	computed: {
		landList() {
			return this.$store.state.farmerData.farmerLands;
		},
		cropList() {
			return this.$store.state.farmerData.farmerCrops;
		},
		getBreadCrumbs() {
			var currentPath = this.$route.path;
			var breadcrumbs = [];
			
			do {
				var name = currentPath.substring(currentPath.lastIndexOf('/') + 1);
				currentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
				
				if (/^\d+$/.test(name)) {
					// Name is only digits, don't add it to breadcrumbs
					continue;
				}

				breadcrumbs.unshift({
					name,
					path: currentPath + '/' + name
				});
			} while (name != this.$options.name);

			if (breadcrumbs.length == 1) {
				return [];
			} else {
				return breadcrumbs;
			}
		},
		isRootPath() {
			var currentPath = this.$route.path;
			return currentPath.substring(currentPath.lastIndexOf('/') + 1) == this.$options.name;
		}
	},
	watch: {
		landList() {
			if (this.landList.length > 0) {
				this.selectedLand = this.landList[0];
			} else {
				this.selectedLand = undefined;
			}
		},
		cropList(){
			if (this.cropList.length > 0) {
				this.selectedCrop = this.cropList[0];
			} else {
				this.selectedCrop = undefined;
			}
		}

	},
}
</script>
<style scoped></style>
