<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">Crop Yield Analysis</h1>
					</div>
				</div>
			</div>

			<!--graphs-->
			<div class="card-body">
				<!-- Table -->
				<div class="mb-5 mt-2">
					<div class="card shadow">
						<div class="col card-header text-center">
							<div class="row">
								<div class="col-10">
									<h2>Crop Yield Table</h2>
								</div>
								<div class="col-2 text-right">
									<base-button
									v-if="token ==='admin'"
										type="button"
										class="btn-sm btn-primary"
										@click.prevent="addCropYield(farmerID)"
									>
										Add New +
									</base-button>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="table-responsive">
									<base-table
										class="
											table
											align-items-center
											table-flush
										"
										title=""
										thead-classes="thead-light"
										tbody-classes="list"
										type="hover"
										:data="pagedTableData"
									>
										<template slot="columns">
											<th>Sl No</th>
											<th
												v-for="(
													header, index
												) in tableHeaders"
												v-on:click="sortTable(header)"
												:key="index"
											>
												{{ header }}
												<div
													class="float-right"
													v-if="
														header.toLowerCase() ==
														sortColumn
													"
												>
													<i
														v-bind:class="[
															ascending
																? 'fas fa-arrow-up'
																: 'fas fa-arrow-down',
														]"
													></i>
												</div>
											</th>
											<th v-if="token ==='admin'">Actions</th>
										</template>

										<template slot-scope="{ row, index }">
											<td>{{ index + 1 }}</td>
											<td>{{ row.year }}</td>
											<td>
												{{ row.quantity }}
												{{ row.quantityUnit }}
											</td>
											<td>
												{{ row.quality }}
												{{ row.qualityUnit }}
											</td>
											<td>
												{{ row.predictedYield }}
												{{ row.predictedUnit }}
											</td>
											<td v-if="token ==='admin'">
												<i
													class="btn fas fa-eye"
													@click.prevent="
														editData(row, true)
													"
												></i>
												<i
													class="btn fas fa-pen"
													@click.prevent="
														editData(row, false)
													"
												></i>
												<i
													class="
														btn
														fas
														fa-trash
														text-danger
													"
													@click.prevent="
														deleteItemIndex =
															tableData.indexOf(
																pagedTableData[
																	index
																]
															)
													"
												></i>
											</td>
										</template>
									</base-table>
								</div>
							</div>
						</div>
						<base-pagination
							class="mt-3 mr-3"
							:perPage="pageSize"
							:total="filteredTableData.length"
							v-model="currentPage"
							align="center"
						></base-pagination>
						<delete-dialog
							v-model="deleteItemIndex"
							:data="tableData"
							item="crop yield"
							@performingAction="displayLoader = true"
							@actionComplete="displayLoader = false; updateChartData()"
							@actionFailed="displayLoader = false"
							actionString="deleteCropYield"
						/>
					</div>
				</div>

				<div class="row">
					<div class="container-fluid">
						<div class="row">
							<div class="col-xl-4 mb-5 mb-xl-0">
								<line-chart-view
									type="default"
									:chartData="chartData[0]"
								></line-chart-view>
							</div>

							<div class="col-xl-4 mb-5 mb-xl-0">
								<line-chart-view
									type="default"
									:chartData="chartData[1]"
								></line-chart-view>
							</div>

							<div class="col-xl-4 mb-5 mb-xl-0">
								<line-chart-view
									type="default"
									:chartData="chartData[2]"
								></line-chart-view>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
	name: "crop-yield-analysis",
	components: {
		DeleteDialog,
	},
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
	},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			displayLoader: false,
			deleteItemIndex: -1,
			pageSize: 15,
			currentPage: 1,
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.tableData.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		addCropYield() {
			this.$router.push({
				name: "add crop yield",
				params: {
					crop: this.selectedCrop,
					landID: this.selectedLandID,
				},
			});

		},
		editData(row, viewOnly) {
			var editItemIndex = this.tableData.indexOf(row);
			var itemData = Object.assign({}, this.tableData[editItemIndex]);
			this.$router.push({
				name: "edit crop yield",
				params: {
					editItem: {
						data: itemData,
						index: editItemIndex,
					},
					crop: this.selectedCrop,
					landID: this.selectedLandID,
					viewOnly,
				},
			});
		},
		updateChartData() {
			for (var i = 0; i < this.chartData.length; i++) {
				var label = "Unknown";
				var years = [];
				var data = [];

				if (i == 0) {
					label = "Quantity";
				} else if (i == 1) {
					label = "Quality";
				} else if (i == 2) {
					label = "Yield";
				}

				for (var j = 0; j < this.tableData.length; j++) {
					var item = this.tableData[j];
					years.push(item.year);
					if (i == 0) {
						data.push(item.quantity);
					} else if (i == 1) {
						data.push(item.quality);
					} else if (i == 2) {
						data.push(item.predictedYield);
					}
				}

				var subTitle = Math.min(...years) + " - " + Math.max(...years);

				let cd = {
					labels: years,
					datasets: [{ label, data }],
				};


				this.chartData[i].subTitle = subTitle;
				this.chartData[i].chartData = cd;
			}
		},
		fetchCropYields() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchCropYield", { farmerID: this.farmerID, landID: this.selectedLandID,crop:this.selectedCrop })
				.then(() => {
					this.displayLoader = false;
					this.updateChartData();
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop yields",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		}
	},
	computed: {
		tableHeaders() {
			return this.cropYieldData.tableHeaders;
		},
		tableData() {
			return this.cropYieldData.cropYieldList;
		},
		filteredTableData() {
			var filteredTableData = this.tableData;
			return filteredTableData;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.filteredTableData.slice(start, start + this.pageSize);
		},
		chartData() {
			return this.cropYieldData.chartData;
		},
		cropYieldData() {
			return this.$store.state.farmerData.cropYieldData;
		},
		token(){
			return localStorage.getItem('decoded-token');
		}
	},
	mounted() {
		this.$store.dispatch("fetchFarmerland",this.farmerID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch Land",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
				if(this.selectedLandID !=undefined){
					this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
					.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		}
			if(this.selectedCrop !=undefined){
				this.fetchCropYields();
			}

		this.updateChartData();
	},
	watch: {
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		},
		selectedCrop(){
			this.fetchCropYields();

		}
	}
};
</script>
