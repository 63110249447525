<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center">
					<div class="col-10">
						<h1 class="display-4 m-0">Tasks</h1>
					</div>
                    <div class="col-2 text-right">
						<base-button
							type="button"
							class="btn btn-primary"
							@click.prevent="addTask()"
						>
							Add +
						</base-button>
					</div>
				</div>
			</div>
			<!--Search and Date -->
			<div class="card-body">
				<!-- <div class="container">
					<div class="row">
						<div class="col-1">
							<label
								class="form-control-label mt-2 col text-center"
								>Date</label
							>
						</div>
						<div class="col-11">
							<base-input
								type="date"
								class="col-lg-2"
								v-model="startDate"
							/>
						</div>
					</div>
				</div> -->

				<!-- Table -->
				<div class="mb-3">
					<div class="card shadow">
						<div class="row">
							<div class="col">
								<div class="table-responsive">
									<base-table
										class="table align-items-center table-flush"
										title=""
										thead-classes="thead-light"
										tbody-classes="list"
										type="hover"
										:data="pagedTableData"
									>
										<template slot="columns">
											<th>Sl No</th>
											<th
												v-for="(
													header, index
												) in tableHeaders"
												v-on:click="sortTable(header)"
												:key="index"
											>
												{{ header }}
												<div
													class="float-right"
													v-if="
														header.toLowerCase() ==
														sortColumn
													"
												>
													<i
														v-bind:class="[
															ascending
																? 'fas fa-arrow-up'
																: 'fas fa-arrow-down',
														]"
													></i>
												</div>
											</th>
                                            <th>Actions</th>
										</template>

										<template slot-scope="{ row, index }">
											<td>{{
												pageSize * (currentPage - 1) +
												(index + 1)
											}}</td>
											<td>{{ row.task_date }}</td>
											<td>
												{{ row.task_name}}
											</td>
											<td>{{ row.is_completed&&row.is_completed===true?'Completed':'Incomplete'}}</td>
											
											<td>
												
												<i
													class="btn fas fa-pen"
													@click.prevent="
														editData(row.id)
													"
												></i>
												<i
													class="btn fas fa-trash text-danger"
													@click.prevent="
														deleteItemIndex =
															tableData.indexOf(
																pagedTableData[
																	index
																])"
												></i>
											</td>
										</template>
									</base-table>
								</div>
							</div>
						</div>
						<base-pagination
							class="mt-3 mr-3"
							:perPage="pageSize"
							:total="tableData.length"
							v-model="currentPage"
							align="center"
						></base-pagination>
		
					</div>
				</div>
			</div>
            <delete-dialog
				:data="tableData"
				v-model="deleteItemIndex"
				actionString="deleteTask"
				item="task"
				:miscData="{ executiveID: executiveID }"
			>
				<span slot="warning">This data will be removed!</span>
			</delete-dialog>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
	name: "attendance",
    components: {
		DeleteDialog,
	},
	props: {
		executiveID: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			deleteItemIndex: -1,
			pageSize: 10,
			displayLoader: false,
			currentPage: 1,
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.tableData.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		fetchTaskList(){
            this.displayLoader = true;
			this.$store
				.dispatch("fetchTaskList", this.executiveID)
                .then(() => {this.displayLoader = false;})
				.catch((error) => {
                    this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch executive task",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
		},
        addTask(){
            this.$router.push('tasks/add')
        },
        editData(id){
            this.$router.push(`tasks/${id}/edit`)
        }
	},
	computed: {
		tableHeaders() {
			return this.$store.state.ExecutiveData.TaskData.tableHeaders;
		},
		tableData() {
			return this.$store.state.ExecutiveData.TaskData.taskList;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		}
        ,},
	mounted() {
		this.fetchTaskList();

	}
};
</script>
<style lang="scss" scoped></style>
