var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-4"},[_c('h2',[_vm._v("Diseases")]),_c('div',{staticClass:"btn border border-success py-2 px-3 h-50 text-right",on:{"click":function($event){$event.preventDefault();return _vm.$router.push('diseases/add')}}},[_vm._v(" Add + ")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between my-5"},[_c('base-input',{staticClass:"input-group w-25 m-0",attrs:{"placeholder":'Search Diseases',"addon-right-icon":"fas fa-search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"table-responsive mt-5"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.pagedTableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.dieseas_name))]),_c('td',[_vm._v(_vm._s(row.cause))]),_c('td',[_vm._v(_vm._s(row.symptoms))]),_c('td',[_c('a',{attrs:{"target":"__blank","href":row.dieseas_image}},[_vm._v(_vm._s(row.dieseas_image !==null ?'View File':'No Files added'))])]),_c('td',[_c('i',{staticClass:"btn fas fa-eye",on:{"click":function($event){$event.preventDefault();return _vm.editData(row, true)}}}),_c('i',{staticClass:"btn fas fa-pen",on:{"click":function($event){$event.preventDefault();return _vm.editData(row, false)}}}),_c('i',{staticClass:"btn fas fa-trash text-danger",on:{"click":function($event){$event.preventDefault();_vm.deleteItemIndex = _vm.tableData.indexOf(
										_vm.pagedTableData[index]
									)}}})])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_c('th',{on:{"click":function($event){return _vm.sortTable()}}},[_vm._v(" Disease Name "),_c('div',{staticClass:"float-right"},[_c('i',{class:[
										_vm.ascending
											? 'fas fa-arrow-up'
											: 'fas fa-arrow-down'
									]})])]),_c('th',[_vm._v("Cause")]),_c('th',[_vm._v("Symptoms")]),_c('th',[_vm._v("File")]),_c('th',[_vm._v("Actions")])])],2)],1),_c('base-pagination',{staticClass:"mt-3 mr-3",attrs:{"perPage":_vm.pageSize,"total":_vm.tableData.length,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('delete-dialog',{attrs:{"data":_vm.tableData,"actionString":"deleteDisease"},on:{"actionComplete":function($event){_vm.displayLoader = false; _vm.deleteItemIndex = -1},"actionCancelled":function($event){_vm.deleteItemIndex = -1},"performingAction":function($event){_vm.displayLoader = true},"actionFailed":function($event){_vm.displayLoader = false}},model:{value:(_vm.deleteItemIndex),callback:function ($$v) {_vm.deleteItemIndex=$$v},expression:"deleteItemIndex"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }