var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('base-input',{staticClass:"input-group-alternative col-lg-5",attrs:{"placeholder":"Search","addon-right-icon":"fas fa-search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"col card-header text-center"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-2 text-right"},[(_vm.token === 'admin')?_c('base-button',{staticClass:"btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addProductivity(_vm.farmerID)}}},[_vm._v(" Add New + ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"\n\t\t\t\t\t\t\t\t\t\ttable\n\t\t\t\t\t\t\t\t\t\talign-items-center\n\t\t\t\t\t\t\t\t\t\ttable-flush\n\t\t\t\t\t\t\t\t\t",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.pagedTableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.name))]),_c('td',[_vm._v(_vm._s(row.startDate))]),_c('td',[_vm._v(_vm._s(row.endDate))]),(_vm.token === 'admin')?_c('td',[_c('i',{staticClass:"btn fas fa-eye",on:{"click":function($event){$event.preventDefault();return _vm.editData(row, true)}}}),_c('i',{staticClass:"btn fas fa-pen",on:{"click":function($event){$event.preventDefault();return _vm.editData(row, false)}}}),_c('i',{staticClass:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tbtn\n\t\t\t\t\t\t\t\t\t\t\t\t\tfas\n\t\t\t\t\t\t\t\t\t\t\t\t\tfa-trash\n\t\t\t\t\t\t\t\t\t\t\t\t\ttext-danger\n\t\t\t\t\t\t\t\t\t\t\t\t",on:{"click":function($event){$event.preventDefault();_vm.deleteItemIndex =
														_vm.tableData.indexOf(
															_vm.pagedTableData[
																index
															]
														)}}})]):_vm._e()]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index,on:{"click":function($event){return _vm.sortTable(header)}}},[_vm._v(" "+_vm._s(header)+" "),(
													header.toLowerCase() ==
													_vm.sortColumn
												)?_c('div',{staticClass:"float-right"},[_c('i',{class:[
														_vm.ascending
															? 'fas fa-arrow-up'
															: 'fas fa-arrow-down' ]})]):_vm._e()])}),(_vm.token === 'admin')?_c('th',[_vm._v("Actions")]):_vm._e()],2)],2)],1)])]),_c('base-pagination',{staticClass:"mt-3 mr-3",attrs:{"perPage":_vm.pageSize,"total":_vm.tableData.length,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('delete-dialog',{attrs:{"data":_vm.tableData,"miscData":{ farmerID: _vm.farmerID },"item":"productivity","actionString":"deleteProductivity"},on:{"performingAction":function($event){_vm.displayLoader = true},"actionComplete":function($event){_vm.displayLoader = false},"actionFailed":function($event){_vm.displayLoader = false}},model:{value:(_vm.deleteItemIndex),callback:function ($$v) {_vm.deleteItemIndex=$$v},expression:"deleteItemIndex"}})],1)])])]),_c('loader',{attrs:{"displayLoader":_vm.displayLoader}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"display-4 m-0"},[_vm._v("Productivity")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-10"},[_c('h2',[_vm._v("Productivity")])])}]

export { render, staticRenderFns }