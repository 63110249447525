<template>
<div class="row">
	<div class="col">
		
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h2>Market Price</h2>
				<span class="text-muted">Send or unsend market price data to the farmer's app</span>
			</div>

			<div
				class="btn border border-primary py-2 px-3 mt-2 h-50 text-right"
				@click.prevent="$router.push('/crops/market-price')"
			>
				Crop market price
			</div>
		</div>

		<div class="table-responsive mt-4">
			<base-table
				class="table align-items-center table-flush"
				thead-classes="thead-light"
				tbody-classes="list"
				type="hover"
				:data="pagedTableData"
			>
				<template slot="columns">
					<th>Sl no</th>
					<th
						v-for="(header, index) in tableHeaders"
						:key="index"
					>
						{{ header }}
					</th>
					<th>Actions</th>
				</template>

				<template slot-scope="{ row, index }">
					<td>{{ index + 1 }}</td>
					<td>{{ row.date }}</td>
					<td>{{ row.gl_520 }} <span class="text-muted pl-1">₹</span></td>
					<td>{{ row.gl_550 }} <span class="text-muted pl-1">₹</span></td>
					<td>{{ row.gl_570 }} <span class="text-muted pl-1">₹</span></td>
					<td>{{ row.mg1 }} <span class="text-muted pl-1">₹</span></td>
					<td>
						<i class="btn fas" :class="[ row[row.length - 2] ? 'fa-undo text-danger' : 'fa-paper-plane text-success' ]"
							@click.prevent="sendData(row[row.length - 1])"> 
							{{ row[row.length - 2] ? "Undo" : "Send" }}
						</i>
					</td>
				</template>
			</base-table>
			<base-pagination class="mt-3 mr-3" :perPage="pageSize" :total="tableData.length" v-model="currentPage" align="center"></base-pagination>
		</div>
	</div>

	<loader :displayLoader="displayLoader"></loader>
</div>
</template>
<script>
export default {
	name: "price-prediction",
	data() {
		return {
			pageSize: 15,
			currentPage: 1,
			displayLoader: false,

		};
	},

	methods: {
		sendData(/* id */) {
			this.$notify({
				title: "This feature is not available yet",
				icon: 'fa fa-exclamation-circle',
				type: 'warning',
			});
			/* this.displayLoader = true;
			this.$store.commit('sendPrice', id);
			this.displayLoader = false;
			this.$notify({
				title: "Action completed successfully",
				icon: 'fa fa-check',
				type: 'success',
			}); */
		}
	},
	computed: {
		tableData() {
			return this.$store.state.cropData.marketPrice.tableData;
		},
		tableHeaders() {
			return this.$store.state.cropData.marketPrice.headers;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		}
	},
	mounted() {
		this.displayLoader = true;
		this.$store
			.dispatch("fetchMarketPriceList", { crop: undefined,start_date:undefined,end_date:undefined })
			.then(() => {
				this.$store.state.cropData.marketPrice.loaded = true;
			})
			.catch(error => {
				this.$notify({
					title: "Failed to fetch market prices",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString()
				});
			})
			.then(() => {
				this.displayLoader = false;
			});
	}
};
</script>
<style scoped>
</style>
