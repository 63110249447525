<template>
<div>
	<label>
		<input type='checkbox' :checked='calendarOptions.weekends' @change='handleWeekendsToggle' />
		Toggle Weekends
	</label>

	<FullCalendar class='demo-app-calendar' :options='calendarOptions'>
		<template v-slot:eventContent='arg'>
			<b>{{ arg.timeText }}</b>
			<span class="ml-1">{{ arg.event.title }}</span>
		</template>
	</FullCalendar>

	<div class="mt-3">
		<h2>All Events ({{ currentEvents.length }})</h2>
		<ul>
			<li v-for='(event, index) in currentEvents' :key='index'>
				<b>{{ new Date(event.startStr + ((event.allDay) ? 'T00:00:00': '')).toLocaleString() }}:</b>
				<span class="ml-2">{{ event.title }}</span>
			</li>
		</ul>
	</div>

	<modal :show="showEventAddDialog" type="mini" @close="toggleEventAddDialog">
		<template v-slot:header>
			Add an event
		</template>
		Event name: <input v-model="eventDialog.eventName" type="text" class="ml-4" placeholder="Event name here" />
		<br><br>
		Start date: <span class="pl-4 ml-3">{{ new Date(eventDialog.startDate).toLocaleString() }}</span>
		<br><br>
		End date: <span class="pl-5">{{ new Date(eventDialog.endDate).toLocaleString() }}</span>
		<br><br>
		Tags: <br>
			<div v-for="(tag, index) in allTags" :key="index" class="ml-4">
				<input type="checkbox" v-model="eventDialog.tags" :value="tag" class="ml-2" /> {{ tag }}
			</div>
		<template v-slot:footer>
			<button class="btn btn-success" @click.prevent="addEvent">Add</button>
		</template>
	</modal>

	<modal :show="showEventInfoDialog" type="mini" @close="toggleEventInfoDialog">
		<template v-slot:header>
			Event Info
		</template>
		Event name: <span class="pl-2">{{ eventDialog.eventName }}</span>
		<br><br>
		Start date: <span class="pl-3 ml-1">{{ new Date(eventDialog.startDate).toLocaleString() }}</span>
		<br><br>
		End date: <span class="pl-4 ml-1">{{ new Date(eventDialog.endDate).toLocaleString() }}</span>
		<br><br>
		Tags:
			<span v-if="eventDialog.tags.length > 0" class="pl-5 ml-2">{{ eventDialog.tags }}</span>
			<span class="pl-5 ml-2" v-else>No tags available</span>
		<template v-slot:footer>
			<button class="btn btn-danger" @click.prevent="deleteEvent">Delete</button>
		</template>
	</modal>
</div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
	name: 'full-calendar',
	components: {
		FullCalendar
	},
	data: function() {
		return {
			calendarOptions: {
				plugins: [
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin // needed for dateClick
				],
				headerToolbar: {
					left: 'prev,next today',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay'
				},
				initialView: 'dayGridMonth',
				initialEvents: this.$store.state.calendar.events, // alternatively, use the `events` setting to fetch from a feed
				editable: true,
				selectable: true,
				selectMirror: true,
				dayMaxEvents: true,
				weekends: true,
				select: this.handleDateSelect,
				eventClick: this.handleEventClick,
				eventsSet: this.handleEvents
				/* You can update a remote database when these fire:
					eventAdd:
					eventChange:
					eventRemove:
				*/
			},
			currentEvents: [],

			allTags: this.$store.state.calendar.tags,

			showEventAddDialog: false,
			showEventInfoDialog: false,

			selectInfo: '',
			allDay: '',
			clickEvent: '',

			eventDialog: {
				eventName: '',
				startDate: '',
				endDate: '',
				tags: [],
			},
		}
	},

	methods: {
		handleWeekendsToggle() {
			this.calendarOptions.weekends = !this.calendarOptions.weekends
		},
		handleDateSelect(selectInfo) {
			this.selectInfo = selectInfo;

			let calendarApi = selectInfo.view.calendar
			calendarApi.unselect() // clear date selection

			this.eventDialog.startDate = selectInfo.startStr;
			this.eventDialog.endDate = selectInfo.endStr;
			this.eventDialog.eventName = '';
			this.eventDialog.tags = [];
			this.allDay = selectInfo.allDay;

			if (this.allDay) {
				this.eventDialog.startDate += 'T00:00:00';
				this.eventDialog.endDate += 'T00:00:00';
			}

			this.toggleEventAddDialog();
		},
		handleEventClick(clickInfo) {
			this.clickEvent = clickInfo.event;
			this.eventDialog.startDate = clickInfo.event.startStr;
			this.eventDialog.endDate = clickInfo.event.endStr;
			this.eventDialog.eventName = clickInfo.event.title;
			this.eventDialog.tags = clickInfo.event.extendedProps.tags;

			if (this.clickEvent.allDay) {
				this.eventDialog.startDate += 'T00:00:00';
				this.eventDialog.endDate += 'T00:00:00';
			}

			this.toggleEventInfoDialog();
		},
		toggleEventAddDialog() {
			this.showEventAddDialog = !this.showEventAddDialog;
		},
		toggleEventInfoDialog() {
			this.showEventInfoDialog = !this.showEventInfoDialog;
		},
		addEvent() {
			var title = this.eventDialog.eventName;
			if (title.trim() == '') {
				this.$notify({
					title: "Event name is required",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
				});
				return
			}

			if (!this.allDay) {
				var hour = parseInt(new Date(this.eventDialog.startDate).toLocaleTimeString().slice(0, 2));
				if (hour <= 6 && !confirm("Are you sure you want to schedule the event this early (< 6 AM)?")) {
					return;
				} else if (hour >= 23 && !confirm("Are you sure you want to schedule the event this late (> 11 PM)?")) {
					return;
				}

				this.$store.commit('addNewCalendarEvent', {
					title,
					start: this.eventDialog.startDate,
					end: this.eventDialog.endDate,
					tags: this.eventDialog.tags,
				})
			} else {
				this.$store.commit('addNewCalendarEvent', {
					title,
					start: this.eventDialog.startDate.slice(0, this.eventDialog.startDate.length - 9),
					end: this.eventDialog.endDate.slice(0, this.eventDialog.startDate.length - 9),
					tags: this.eventDialog.tags,
				})
			}

			let calendarApi = this.selectInfo.view.calendar
			calendarApi.addEvent({
				title,
				start: this.eventDialog.startDate,
				end: this.eventDialog.endDate,
				allDay: this.allDay,
				extendedProps: {
					tags: this.eventDialog.tags,
				},
			})
			this.toggleEventAddDialog();
		},
		deleteEvent() {
			if (confirm(`Are you sure you want to delete the event '${this.clickEvent.title}'?`)) {
				this.clickEvent.remove();
				this.toggleEventInfoDialog();
			}
		},
		handleEvents(events) {
			this.currentEvents = events
		}
	}
}
</script>
<style scoped>
</style>
