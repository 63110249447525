<template>
	<modal :show="showUserInfoDialog" type="notice" @close="$emit('actionCancelled')">
		<template v-slot:header>
			User details
		</template>
		<div v-if="selectedUser">
			<div class="row py-1">
				<div class="col-3 form-control-label">Name: </div>
				<div class="col-9">{{ selectedUser.name }}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">Email ID: </div>
				<div class="col-9">{{ selectedUser.email }}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">DOB: </div>
				<div class="col-9">{{ selectedUser.date_of_birth }}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">Address: </div>
				<div class="col-9">{{ selectedUser.address }}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">Mobile: </div>
				<div class="col-9">{{ selectedUser.mobile_number }}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">User type: </div>
				<div class="col-9">{{ selectedUser.userType }}</div>
			</div>
		</div>
		<template v-slot:footer>
			<button class="btn btn-danger" @click.prevent="$emit('actionDelete')">Delete user</button>
			<button class="btn btn-danger" @click.prevent="$emit('actionReset')">Reset password</button>
			<button class="btn btn-warning" @click.prevent="$emit('actionEdit')">Edit</button>
			<button class="btn btn-success" @click.prevent="$emit('actionCancelled')">OK</button>
		</template>
	</modal>
</template>

<script>
export default {
	name: 'info-dialog',
	props: {
		selectedUser: {
			type: Object,
			default: undefined,
		},
		showUserInfoDialog: {
			type: Boolean,
			default: false,
		}
	},
}
</script>
<style scoped>
</style>
