<template>
<div>
	<base-header class="header pt-6 pb-5 d-flex align-items-center">
		<!-- Mask -->
		<span class="mask bg-gradient-success opacity-8"></span>
		<!-- Header container -->
		<div class="container-fluid d-flex align-items-center">
			<div class="row">
				<div class="col-sm-12">
					<!-- <h1 class="display-3 text-white">Farmer Dashboard</h1>
					<p class="text-white mt-0 mb-0">Manage all farmer data here</p> -->
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt-3">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-8">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb bg-transparent">
								<li v-for="(breadcrumb, index) in getBreadCrumbs" :key="index" class="breadcrumb-item">
									<a @click.prevent="$router.push(breadcrumb.path)" href="" v-if="$route.path != breadcrumb.path"> {{ breadcrumb.name }} </a>
									<span v-else class="text-muted"> {{ breadcrumb.name }} </span>
								</li>
							</ol>
						</nav>
					</div>
				</div>
				<FadeTransition :duration="200" mode="out-in">
					<router-view  />
				</FadeTransition>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import {
	FadeTransition
} from 'vue2-transitions';

export default {
	name: 'ecommerce-section',
	components: {
		FadeTransition
	},
	data() {
		return {
			
		}
	},
	methods: {
		capitalizeWord(word) {
			return word[0].toUpperCase() + word.slice(1);
		}
	},
	computed: {

		getBreadCrumbs() {
			var currentPath = this.$route.path;
			var breadcrumbs = [];
			
			do {
				var name = currentPath.substring(currentPath.lastIndexOf('/') + 1);
				currentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
				
				if (/^\d+$/.test(name)) {
					// Name is only digits, don't add it to breadcrumbs
					continue;
				}

				breadcrumbs.unshift({
					name,
					path: currentPath + '/' + name
				});
			} while (name != this.$options.name);

			if (breadcrumbs.length == 1) {
				return [];
			} else {
				return breadcrumbs;
			}
		},

	},
	watch: {


	},
}
</script>
<style scoped></style>
