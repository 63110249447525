<template>
	<div>
		<div class="row">
			<div class="col">
				<div
					class="d-flex justify-content-between align-items-center mb-4"
				>
					<h2>Market Price</h2>

					<div
						class="btn border border-success py-2 px-3 mt-2 h-50 text-right"
						@click.prevent="showDialog = true"
					>
						Add +
					</div>
				</div>

				<div class="text-center">
					Showing prices between
					<div
						class="d-flex justify-content-center align-items-center"
					>
						<base-input
							class="my-0 p-2"
							type="date"
							v-model="dateStart"
						/>
						and
						<base-input
							class="my-0 p-2"
							type="date"
							v-model="dateEnd"
						/>
					</div>
				</div>

				<div class="table-responsive mt-4">
					<base-table
						class="table align-items-center table-flush"
						thead-classes="thead-light"
						tbody-classes="list"
						type="hover"
						:data="pagedTableData"
					>
						<template slot="columns">
							<th>SL</th>
							<th
								v-for="(header, index) in tableHeaders"
								:key="index"
							>
								{{ header }}
							</th>
							<th>Actions</th>
						</template>

						<template slot-scope="{ row, index }">
							<td>{{ index + 1 }}</td>
							<td>{{ row.date }}</td>
							<td>
								{{ row.gl_520 }}
								<span class="text-muted pl-1">₹</span>
							</td>
							<td>
								{{ row.gl_550 }}
								<span class="text-muted pl-1">₹</span>
							</td>
							<td>
								{{ row.gl_570 }}
								<span class="text-muted pl-1">₹</span>
							</td>
							<td>
								{{ row.mg1 }}
								<span class="text-muted pl-1">₹</span>
							</td>
							<td>
								<!-- <i class="btn fas fa-eye" @click.prevent=""></i> -->
								<i
									class="btn fas fa-pen"
									@click.prevent="editData(row.id)"
								></i>
								<i
									class="btn fas fa-trash text-danger"
									@click.prevent="
										deleteItemIndex = tableData.indexOf(
											pagedTableData[index]
										)
									"
								></i>
							</td>
						</template>
					</base-table>
				</div>
				<base-pagination
					class="mt-3 mr-3"
					:perPage="pageSize"
					:total="tableData.length"
					v-model="currentPage"
					align="center"
				></base-pagination>
			</div>

			<modal :show="showDialog" @close="showDialog = false">
				<template v-slot:header>
					Market data
				</template>
				<div class="row align-items-center my-4">
					<div class="col-4 mt-3">
						<label class="form-control-label my-0">Date</label>
					</div>
					<div class="col-8 mt-3">
						<base-input
							class="my-0"
							type="date"
							min="0"
							v-model="itemData.date"
						/>
					</div>
					<div class="col-4 mt-3">
						<label class="form-control-label my-0">520 G/L:</label>
					</div>
					<div class="col-8 mt-3">
						<base-input
							class="my-0"
							type="number"
							min="0"
							addonRightIcon="fa fa-rupee-sign"
							v-model="itemData.gl_520"
						/>
					</div>
					<div class="col-4 mt-3">
						<label class="form-control-label my-0">550 G/L:</label>
					</div>
					<div class="col-8 mt-3">
						<base-input
							class="my-0"
							type="number"
							min="0"
							addonRightIcon="fa fa-rupee-sign"
							v-model="itemData.gl_550"
						/>
					</div>
					<div class="col-4 mt-3">
						<label class="form-control-label my-0">570 G/L:</label>
					</div>
					<div class="col-8 mt-3">
						<base-input
							class="my-0"
							type="number"
							min="0"
							addonRightIcon="fa fa-rupee-sign"
							v-model="itemData.gl_570"
						/>
					</div>
					<div class="col-4 mt-3">
						<label class="form-control-label my-0">MG1:</label>
					</div>
					<div class="col-8 mt-3">
						<base-input
							class="my-0"
							type="number"
							min="0"
							addonRightIcon="fa fa-rupee-sign"
							v-model="itemData.mg1"
						/>
					</div>
				</div>
				<template v-slot:footer>
					<button
						class="btn btn-danger"
						@click.prevent="
							resetDialogData();
							showDialog = false;
						"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="applyDialogData()"
					>
						OK
					</button>
				</template>
			</modal>

			<delete-dialog
				v-model="deleteItemIndex"
				:data="tableData"
				actionString="deleteMarketPriceData"
				@actionComplete="
				displayLoader = false;
				deleteItemIndex = -1;"
				@actionCancelled="deleteItemIndex = -1"
				@performingAction="displayLoader = true"
				@actionFailed="displayLoader = false"
			/>

			<loader :displayLoader="displayLoader"></loader>
		</div>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";

export default {
	name: "market-price",
	components: {
		DeleteDialog
	},
	props: {
		selectedCrop: Number
	},
	data() {
		return {
			showDialog: false,
			dialogData: [new Date().toISOString().split("T")[0], 0, 0, 0, 0],
			// dateStart: "2020-01-01",
			dateEnd: new Date().toISOString().split("T")[0],
			dateStart:'2022-01-01',
			// dateEnd:'',
			editItemIndex: -1,
			deleteItemIndex: -1,
			pageSize: 15,
			currentPage: 1,
			displayLoader: false,
			itemData: {
				crop: "",
				date: "",
				gl_520: 0,
				gl_550: 0,
				gl_570: 0,
				mg1: 0
			}
		};
	},
	methods: {
		getProperDate(date) {
			return date
				.toJSON()
				.slice(0, 10)
				.split("-")
				.reverse()
				.join("/");
		},
		getInputFormatDate(dateStr) {
			var dateParts = dateStr.split("/");
			return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
		},
		resetDialogData() {
			this.dialogData = [
				new Date().toISOString().split("T")[0],
				0,
				0,
				0,
				0
			];
		},
		applyDialogData() {
			var invalidValue = false;

			this.itemData.date = this.itemData.date.trim();
			if (this.itemData.date == "") {
				invalidValue = true;
			}
			if (this.itemData.gl_520 == 0) {
				invalidValue = true;
			}
			if (this.itemData.gl_550 == 0) {
				invalidValue = true;
			}
			if (this.itemData.gl_570 == 0) {
				invalidValue = true;
			}
			if (this.itemData.mg1 == 0) {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
				return;
			}

			this.displayLoader = true;
			// Add new action params
			var actionString = "add";
			var actionObject = {
				marketPriceData: this.itemData
			};

			if (this.itemData.id != undefined) {
				// Edit existing action params
				actionString = "edit";
				actionObject = {
					marketPriceData: this.itemData
				};
			}

			this.displayLoader = false;

			this.$store
				.dispatch( "setMarketPriceDetails", {actionObject:actionObject})
				.then(() => {
					this.editItemIndex = -1;
					this.showDialog = false;

					if (this.editItemIndex == -1) {
						this.currentPage = 1;
					}
				})
				.catch(error => {
					this.$notify({
						title: "Failed to " + actionString + " market price",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
		editData(id) {
			this.dialogData = this.tableData.find(item => item.id == id);
			this.itemData = JSON.parse(JSON.stringify(this.dialogData));

			// this.dialogData[0] = this.getInputFormatDate(this.dialogData[0]);
			this.showDialog = true;
		},
		normalizeRow(row) {
			return row.slice(0, this.tableHeaders.length);
		},
		fetchMarketPriceList(crop) {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchMarketPriceList", { crop: crop,start_date:this.dateStart,end_date:this.dateEnd })
				.then(() => {
					this.$store.state.cropData.marketPrice.loaded = true;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch market prices",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
		selectCrop() {
			this.itemData.crop = this.selectedCrop
		}
	},
	computed: {
		tableData() {
			return this.$store.state.cropData.marketPrice.tableData;
		},

		tableHeaders() {
			return this.$store.state.cropData.marketPrice.headers;
		},
		filteredTableData() {
			var filteredTableData = this.tableData;

			var dateStart = undefined;
			var dateEnd = undefined;
			if (this.dateStart) {
				dateStart = new Date(this.getInputFormatDate(this.dateStart));
				dateStart.setHours(0, 0, 0, 0);
			}
			if (this.dateEnd) {
				dateEnd = new Date(this.getInputFormatDate(this.dateEnd));
				dateEnd.setHours(0, 0, 0, 0);
			}

			filteredTableData = filteredTableData.filter(item => {
				var itemDate = new Date(this.getInputFormatDate(item[0]));
				itemDate.setHours(0, 0, 0, 0);

				if (dateStart && itemDate < dateStart) {
					return false;
				}
				if (dateEnd && itemDate > dateEnd) {
					return false;
				}

				return true;
			});

			return filteredTableData;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		}
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
			this.fetchMarketPriceList(this.selectedCrop);
		},
		showDialog() {
			if (this.showDialog == false) {
				(this.itemData.date = ""),
					(this.itemData.gl_520 = 0),
					(this.itemData.gl_550 = 0),
					(this.itemData.gl_570 = 0),
					(this.itemData.mg1 = 0);
			}
		},
		dateEnd(){
			this.fetchMarketPriceList(this.selectedCrop);
		},
		dateStart(){
			this.fetchMarketPriceList(this.selectedCrop);
		}
	},
	mounted() {
		this.$store.dispatch("fetchCropList")	
		.catch(error => {
					this.$notify({
						title: "Failed to fetch experts",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
			if(this.selectedCrop !=undefined){
		this.fetchMarketPriceList(this.selectedCrop);
			}
		this.selectCrop()
	}
};
</script>
<style scoped></style>
