<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center">
					<div class="col-12">
						<h1 class="display-4 m-0">Productivity</h1>
					</div>
				</div>
			</div>
			<!--Search and Date -->
			<div class="card-body">
				<div class="container">
					<div class="row">
						<base-input
							placeholder="Search"
							class="input-group-alternative col-lg-5"
							v-model="searchQuery"
							addon-right-icon="fas fa-search"
						>
						</base-input>
					</div>
				</div>

				<!-- Table -->
				<div class="mb-3">
					<div class="card shadow">
						<div class="col card-header text-center">
							<div class="row">
								<div class="col-10">
									<h2>Productivity</h2>
								</div>
								<div class="col-2 text-right">
									<base-button
									v-if="token === 'admin'"
										type="button"
										class="btn-sm btn-primary"
										@click.prevent="
											addProductivity(farmerID)
										"
									>
										Add New +
									</base-button>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="table-responsive">
									<base-table
										class="
											table
											align-items-center
											table-flush
										"
										title=""
										thead-classes="thead-light"
										tbody-classes="list"
										type="hover"
										:data="pagedTableData"
									>
										<template slot="columns">
											<th>Sl No</th>
											<th
												v-for="(
													header, index
												) in tableHeaders"
												v-on:click="sortTable(header)"
												:key="index"
											>
												{{ header }}
												<div
													class="float-right"
													v-if="
														header.toLowerCase() ==
														sortColumn
													"
												>
													<i
														v-bind:class="[
															ascending
																? 'fas fa-arrow-up'
																: 'fas fa-arrow-down',
														]"
													></i>
												</div>
											</th>
											<th v-if="token === 'admin'">Actions</th>
										</template>

										<template slot-scope="{ row, index }">
											<td>{{ index + 1 }}</td>
											<td>{{ row.name }}</td>
											<td>{{ row.startDate }}</td>
											<td>{{ row.endDate }}</td>
											<td v-if="token === 'admin'">
												<i
													class="btn fas fa-eye"
													@click.prevent="
														editData(row, true)
													"
												></i>
												<i
													class="btn fas fa-pen"
													@click.prevent="
														editData(row, false)
													"
												></i>
												<i
													class="
														btn
														fas
														fa-trash
														text-danger
													"
													@click.prevent="
														deleteItemIndex =
															tableData.indexOf(
																pagedTableData[
																	index
																]
															)
													"
												></i>
											</td>
										</template>
									</base-table>
								</div>
							</div>
						</div>
						<base-pagination
							class="mt-3 mr-3"
							:perPage="pageSize"
							:total="tableData.length"
							v-model="currentPage"
							align="center"
						></base-pagination>
						<delete-dialog
							v-model="deleteItemIndex"
							:data="tableData"
							:miscData="{ farmerID }"
							item="productivity"
							@performingAction="displayLoader = true"
							@actionComplete="displayLoader = false"
							@actionFailed="displayLoader = false"
							actionString="deleteProductivity"
						/>
					</div>
				</div>
			</div>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
	name: "productivity",
	components: {
		DeleteDialog,
	},
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
	},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			deleteItemIndex: -1,
			pageSize: 15,
			displayLoader: false,
			currentPage: 1,
			searchQuery: "",
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.tableData.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		addProductivity() {
			this.$router.push({
				name: "add productivity",
				params: {
					crop: this.selectedCrop,
					landID: this.selectedLandID,
				},
			});
		},
		editData(row, viewOnly) {
			var editItemIndex = this.tableData.indexOf(row);
			var itemData = Object.assign({}, this.tableData[editItemIndex]);
			this.$router.push({
				name: "edit productivity",
				params: {
					editItem: {
						data: itemData,
						index: editItemIndex,
					},
					crop: this.selectedCrop,
					landID: this.selectedLandID,
					viewOnly,
				},
			});
		},
		fetchProductivity() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchProductivity", { farmerID: this.farmerID, land: this.selectedLandID,searchQuery:this.searchQuery,crop:this.selectedCrop })
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch productivity",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		}
	},
	computed: {
		productivityData() {
			return this.$store.state.farmerData.productivityData;
		},
		tableHeaders() {
			return this.productivityData.tableData.headers;
		},
		tableData() {
			return this.productivityData.tableData.data;
		},
		// filteredTableData() {
		// 	var filteredTableData = this.tableData;
		// 	if (this.searchQuery != "") {
		// 		filteredTableData = this.tableData.filter((item) =>
		// 			item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
		// 		);
		// 	}
		// 	return filteredTableData;
		// },
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		},
		token(){
			return localStorage.getItem('decoded-token');
		}
	},
	mounted() {
		this.$store.dispatch("fetchFarmerland",this.farmerID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch Land",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
				if(this.selectedLandID !=undefined){
					this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
					.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		}
		if(this.selectedCrop !=undefined){
		this.fetchProductivity();
		}
	},
	watch: {
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		if(this.selectedCrop !=undefined){
		this.fetchProductivity();
		}
		},
		selectedCrop(){
			this.fetchProductivity();

		},

		searchQuery(){

			this.fetchProductivity();
		}
	}
};
</script>
<style lang="scss" scoped>
</style>