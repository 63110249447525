<template>
	<div>
		<div class="row">
			<div class="col">
				<div
					class="d-flex align-items-center justify-content-between mb-4"
				>
					<h2>Experts</h2>
					<div
						class="btn border border-success py-2 px-3 h-50 text-right"
						@click.prevent="$router.push('experts/add')"
					>
						Add +
					</div>
				</div>
				<div
					class="d-flex align-items-center justify-content-between my-5"
				>
					<base-input
						:placeholder="'Search Experts'"
						class="input-group w-25 m-0"
						v-model="searchQuery"
						addon-right-icon="fas fa-search"
					></base-input>
				</div>
				<div class="table-responsive mt-5">
					<base-table
						class="table align-items-center table-flush"
						thead-classes="thead-light"
						tbody-classes="list"
						type="hover"
						:data="pagedTableData"
					>
						<template slot="columns">
							<th>Sl No</th>
							<th
								v-for="(header, index) in tableHeaders"
								v-on:click="sortTable(header)"
								:key="index"
							>
								{{ header }}
								<div
									class="float-right"
									v-if="header.toLowerCase() == sortColumn"
								>
									<i
										v-bind:class="[
											ascending
												? 'fas fa-arrow-up'
												: 'fas fa-arrow-down'
										]"
									></i>
								</div>
							</th>
							<th>Actions</th>
						</template>

						<template slot-scope="{ row, index }">
							<td>{{ index + 1 }}</td>
							<td>
								{{ row.name }}
							</td>
							<td>
								{{ row.mobile }}
							</td>
							<td>
								{{ row.email }}
							</td>
							<td>
								{{ row.department }}
							</td>
							<td>
								<i
									class="btn fas fa-eye"
									@click.prevent="editData(row, true)"
								></i>
								<i
									class="btn fas fa-pen"
									@click.prevent="editData(row, false)"
								></i>
								<i
									class="btn fas fa-trash text-danger"
									@click.prevent="
										deleteItemIndex = tableData.indexOf(
											pagedTableData[index]
										)
									"
								></i>
							</td>
						</template>
					</base-table>
				</div>
				<base-pagination
					class="mt-3"
					:perPage="pageSize"
					:total="tableData.length"
					v-model="currentPage"
					align="center"
				></base-pagination>
			</div>
			<delete-dialog
				v-model="deleteItemIndex"
				:data="tableData"
				actionString="deleteExpert"
				@actionComplete="
					displayLoader = false;
					deleteItemIndex = -1;
				"
				@actionCancelled="deleteItemIndex = -1"
				@performingAction="displayLoader = true"
				@actionFailed="displayLoader = false"
			/>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";

export default {
	name: "experts",
	components: {
		DeleteDialog
	},
	props: {
		selectedCrop: Number,
		category: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			deleteItemIndex: -1,
			searchQuery: "",
			pageSize: 15,
			currentPage: 1,
			action: "",
			displayLoader: false
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.tableData.sort(function(a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},

		editData(row, viewOnly) {
			var editItemIndex = this.tableData.indexOf(row);
			var itemData = Object.assign({}, this.tableData[editItemIndex]);
			if (viewOnly) {
				this.action = "view";
			} else {
				this.action = "edit";
			}
			this.$router.push({
				name: "add experts",
				params: {
					action: this.action,
					editItem: {
						data: itemData,
						index: editItemIndex
					},
					viewOnly
				}
			});
		},
		fetchExpertsList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchExpertsList", {searchQuery:this.searchQuery })
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch experts",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},
	computed: {
		expertsData() {
			return this.$store.state.cropData.experts;
		},
		departments() {
			return this.expertsData.departments;
		},
		tableHeaders() {
			return this.expertsData.headers;
		},
		tableData() {
			return this.expertsData.tableData;
		},
		// filteredTableData() {
		// 	var filteredTableData = this.tableData;
		// 	if (this.searchQuery != "") {
		// 		filteredTableData = this.tableData.filter(item =>
		// 			item.name.toLowerCase().includes(this.searchQuery)
		// 		);
		// 	}

		// 	return filteredTableData;
		// },
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		}
	},
	watch: {
		
		searchQuery(){
				// if(this.searchQuery !=''){
				// this.searchQuery=this.searchQuery[0].toUpperCase()+this.searchQuery.slice(1)
				// }
			this.fetchExpertsList(this.selectedCrop);
		}
	},
	mounted() {
		this.$store.dispatch("fetchCropList")	
		.catch(error => {
					this.$notify({
						title: "Failed to fetch experts",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
		this.fetchExpertsList();
	}
};
</script>
<style scoped></style>
