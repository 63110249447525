<template>
	<div>
		<base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-gradient-success opacity-8"></span>
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-sm-12">
						<h1 class="display-2 text-white">Reports</h1>
					</div>
				</div>
				<div class="col mr-4 text-right">
					<base-button
						type="button"
						class="btn btn-danger"
						@click.prevent="goToReportPage"
					>
						Report
					</base-button>
				</div>
			</div>
		</base-header>

		<!--Search and Date -->
		<div class="card-body">
			<div class="container">
				<div class="row">
					<base-input
						placeholder="Search"
						class="input-group-alternative col-lg-6 p-0 mx-3"
						v-model="searchQuery"
						addon-right-icon="fas fa-search"
					>
					</base-input>
					<label class="form-control-label mt-2 col">Start date</label>
					<base-input
						type="date"
						class="col-lg-2"
						v-model="startDate"
					/>
					<label class="form-control-label mt-2 col">End date</label>
					<base-input
						type="date"
						class="col-lg-2"
						v-model="endDate"
					/>
				</div>
			
        <div class="row">
          <div
            v-for="(selector, index) in selectors"
            :key="index"
            class="col-lg-4"
          >
            <label class="form-control-label">{{ selector.heading }}</label>
            <select class="rounded m-0 form-control">
              <option
                v-for="(type, index) in selector.dataStore"
                :key="index">
                {{ type }}
              </option>
            </select>
          </div>
        </div>
      </div>
		</div>

		<!-- Table -->
		<div class="mb-3 px-lg-6 px-sm-4 mt-4">
			<div class="card shadow">
				<div class="row">
					<div class="col">
						<div class="table-responsive">
							<base-table
								class="table align-items-center table-flush"
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="pagedTableData"
							>
								<template slot="columns">
									<th>Sl No</th>
									<th
										v-for="(header, index) in tableHeaders"
										v-on:click="sortTable(header)"
										:key="index"
									>
										{{ header }}
										<div
											class="float-right"
											v-if="
												header.toLowerCase() ==
												sortColumn
											"
										>
											<i
												v-bind:class="[
													ascending
														? 'fas fa-arrow-up'
														: 'fas fa-arrow-down',
												]"
											></i>
										</div>
									</th>
									<th>Actions</th>
								</template>

								<template slot-scope="{ row, index }">
									<td>{{ index + 1 }}</td>
									<td>{{ row.date !=null?row.date.slice(0,10):row.date }}</td>
									<td>{{ row.subject }}</td>
									<td>{{ row.section }}</td>
									<td>
										<i
											class="btn fas fa-eye"
											@click.prevent="editData(row, true)"
										></i>
										<i
											class="btn fas fa-pen"
											@click.prevent="
												editData(row, false)
											"
										></i>
										<i class= "btn fas fa-trash text-danger"   @click.prevent="deleteItemIndex = tableData.indexOf(pagedTableData[index] )"></i>
									</td>
								</template>
							</base-table>
						</div>
					</div>
				</div>
				<delete-dialog
							v-model="deleteItemIndex"
							:data="tableData"
							actionString="deleteReport"
							@actionComplete="
								displayLoader = false;
								deleteItemIndex = -1;"
							@actionCancelled="deleteItemIndex = -1"
							@performingAction="displayLoader = true"
							@actionFailed="displayLoader = false"
						/>
			</div>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>

<script>
import DeleteDialog from "./ItemDeleteDialog";
export default {
	name: "reports",
	components:{DeleteDialog},
	data() {
		return {
			deleteItemIndex:-1,
			reportID:null,
			displayLoader:false,
			ascending: false,
			sortColumn: "",
			pageSize: 15,
			currentPage: 1,
			searchQuery: "",
			startDate: null,
			endDate: null,
			selectors: [
				{
					heading: "Crop",
					dataStore: this.$store.state.reportAnalysisData.cropList,
				},
				{
					heading: "Variety",
					dataStore: this.$store.state.reportAnalysisData.varieties,
				},
				{
					heading: "Locality",
					dataStore: this.$store.state.reportAnalysisData.locality,
				},
			],
		};
	},
	computed: {
		tableHeaders() {
			return this.$store.state.reportAnalysisData.tableHeaders;
		},
		tableData() {
			return this.$store.state.reportAnalysisData.tableData;
		},
		// filteredTableData() {
		// 	var filteredTableData = this.tableData;
		// 	if (this.searchQuery != "") {
		// 		filteredTableData = this.tableData.filter((item) =>
		// 			item.name.toLowerCase().includes(this.searchQuery)
		// 		);
		// 	} else if (this.startDate != "" && this.endDate != "") {
		// 		filteredTableData = this.tableData.filter(
		// 			(item) =>
		// 				item.date >= this.startDate && item.date <= this.endDate
		// 		);
		// 	}

		// 	return filteredTableData;
		// },
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		},
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (col == "name or tag") {
				col = "name";
			}
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.tableData.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		editData(row, viewOnly) {
			var editItemIndex = this.tableData.indexOf(row);
			var itemData = Object.assign({}, this.tableData[editItemIndex]);
			this.$router.push({
				name: "edit report",
				params: {
					editItem: {
						data: itemData,
						index: editItemIndex,
					},
					viewOnly,
				},
			});
		},
		goToReportPage() {
			this.$router.push({ name: 'report-analysis-report', params: { enableBanner: true } });
		},
		fetchReportList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchReportList",{searchQuery:this.searchQuery,startDate:this.startDate,endDate:this.endDate})
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch reports",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},
	watch:{
		searchQuery(){
			this.fetchReportList()
		},
		endDate(){
			this.fetchReportList()
		},
		startDate(){
			this.fetchReportList()
		}

	},
	mounted() {
		this.fetchReportList()
	}
};
</script>

<style scoped>
</style>
