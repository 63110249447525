<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="display-4 m-0">Subscriptions</h1>
          </div>
        </div>
      </div>

      <div class="card shadow bg-secondary m-5 p-3 rounded">
        <label class="form-control-label">Subscription Information</label>
        <div class="row" v-for="(subscriptionData, index) in subscriptionOverviewData" :key="index">
          <div class="col-6">{{ index + 1 }}. {{ subscriptionData.name }}</div>
          <div class="col-6">{{ subscriptionData.value }}</div>
        </div>
      </div>

      <div class="card-body" v-for="count in [0, 1]" :key="count">
        <!--Search and Date -->
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <label class="form-control-label">Search</label>
              <base-input
                placeholder="Search"
                class="input-group-alternative"
                v-model="searchQuery[count]"
                addon-right-icon="fas fa-search"
              >
              </base-input>
            </div>
            <div class="col">
              <label class="form-control-label">Start date</label>
              <base-input type="date" class="" v-model="startDate[count]" />
            </div>
            <div class="col">
              <label class="form-control-label">End date</label>
              <base-input type="date" class="" v-model="endDate[count]" />
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="mb-3 px-lg-7">
          <div class="card shadow border">
            <div class="col card-header text-center">
              <div class="row">
                <div class="col">
                  <div class="display-4">{{ tables[count].name }}</div>
                </div>
                <div class="position-absolute align-self-center right-0 pr-3">
                  <base-button
                    type="button"
                    class="btn-sm btn-primary"
                    @click.prevent="addTableData(count)"
                  >
                    Add New +
                  </base-button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <base-table
                    class="table align-items-center table-flush"
                    title=""
                    thead-classes="thead-light"
                    tbody-classes="list"
                    type="hover"
                    :data="pagedTableData(count)"
                  >
                    <template slot="columns">
                      <th>Sl No</th>
                      <th
                        v-for="(header, index) in tables[count].headers"
                        v-on:click="sortTable(count, header)"
                        :key="index"
                      >
                        {{ header }}
                        <div
                          class="float-right"
                          v-if="header.toLowerCase() == sortColumn[count]"
                        >
                          <i
                            v-bind:class="[
                              ascending
                                ? 'fas fa-arrow-up'
                                : 'fas fa-arrow-down',
                            ]"
                          ></i>
                        </div>
                      </th>
                      <th>Actions</th>
                    </template>

                    <template slot-scope="{ row, index }">
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.created_date }}</td>
                      <td >{{ row.subscription_plan }}</td>                     
                      <td >{{ row.amount }}</td>
                      <td>{{ row.subscription_status }}</td>
                      <td>
                        <i
                          class="btn fas fa-pen"
                          @click.prevent="editData(row, count, false)"
                        ></i>
                        <!-- It doesn't display the dialog without `tables[count].data = tables[count].data.slice()` -->
                        <i
                          class="btn fas fa-trash text-danger"
                          @click.prevent="
                            deleteItemIndex[count] = tables[count].data
                            .indexOf(pagedTableData(count)[index]);
                            tables[count].data = tables[count].data.slice();
                          "
                        ></i>
                      </td>
                    </template>
                  </base-table>
                </div>
              </div>
            </div>

            <base-pagination
              class="mt-3 mr-3"
              :perPage="pageSize[count]"
              :total="filteredTableData(count).length"
              v-model="currentPage[count]"
              align="center"
            ></base-pagination>

            <!-- It doesn't update close the dialog without `tables[count].data = tables[count].data.slice()` -->
            <delete-dialog
            :miscData="{ farmerID }"
              v-model="deleteItemIndex[count]"
              actionString="deleteSubscription"
              :data="tables[count].data"
              @actionComplete="deleteItemIndex[count] = -1"
              @actionCancelled="deleteItemIndex[count] = -1; tables[count].data = tables[count].data.slice();"
            />
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
  name: "subscriptions",
  components: {
    DeleteDialog,
  },
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    selectedLandID: {
      type: Number
    },
  },
  data() {
    return {
      subscriptionOverviewData: [
        { name: 'ID', value: '132', },
        { name: 'Total number of crops', value: '5' },
        { name: 'Total number of vines', value: '32' },
        { name: 'Plan details', value: 'Platinum' },
        { name: 'Completing in', value: '2 months' },
        { name: 'Status', value: 'Active' },
      ],
      ascending: [false, false],
      sortColumn: ["", ""],
      deleteItemIndex: [-1, -1],
      pageSize: [15, 15],
      currentPage: [1, 1],
      searchQuery: ["", ""],
      startDate: ["", ""],
      endDate: ["", ""],
    };
  },
  methods: {
    sortTable: function sortTable(index, header) {
      var col = header.toLowerCase();
      if (this.sortColumn[index] === col) {
        this.ascending[index] = !this.ascending[index];
      } else {
        this.ascending[index] = true;
        this.sortColumn[index] = col;
      }
      var ascending = this.ascending[index];
      this.tables[index].data.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
    addTableData(index) {
      this.$router.push(
        "/farmers-section/" + this.farmerID + "/dashboard/" +
           this.tables[index].name.toLowerCase() + "/add"
      );
    },
    editData(row, index, viewOnly) {
      var editItemIndex = this.tables[index].data.indexOf(row);
      var itemData = Object.assign({}, this.tables[index].data[editItemIndex]);
      this.$router.push({
        name: "edit " + this.tables[index].name.toLowerCase(),
        params: {
          editItem: {
            data: itemData,
            index: editItemIndex,
          },
          viewOnly,
        },
      });
    },
    fetchSubscriptionList(){
      this.displayLoader = true;
			this.$store.dispatch('fetchSubscriptionList',this.farmerID).
      then(() => {
				this.displayLoader = false;
			}).catch((error) => {
				this.displayLoader = false;
				this.$notify({
					title: "Failed to fetch subscription list",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			});
    },
    filteredTableData(index) {
      var filteredTableData = this.tables[index].data;
      if (this.searchQuery[index] != "") {
        if (index == 0) {
          filteredTableData = filteredTableData.filter((item) =>
            item.plan.toLowerCase().includes(this.searchQuery[index])
          );
        } else {
          filteredTableData = filteredTableData.filter((item) =>
            item.quantity.toLowerCase().includes(this.searchQuery[index])
          );
        }
      } else if (this.startDate[index] != "" && this.endDate[index] != "") {
        filteredTableData = filteredTableData.filter(
          (item) => item.date >= this.startDate[index] && 
                    item.date <= this.endDate[index]
        );
      }

      return filteredTableData;
    },
    pagedTableData(index) {
      var start = (this.currentPage[index] - 1) * this.pageSize[index];
      return this.filteredTableData(index).slice(start, start + this.pageSize[index]);
    }
  },
  computed: {
    tables() {
      return this.$store.state.farmerData.subscriptionData.tables;
    },
  },
  mounted() {
    if(this.selectedLandID ===undefined){
      this.$router.back()
    }
    this.fetchSubscriptionList()
  },
};
</script>
<style lang="scss" scoped>
</style>