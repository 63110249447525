<template>
	<div>
		<div class="card-header bg-transparent">
			<div class="row align-items-center">
				<div class="col-8">
					<h5 class="h1 mb-0">Process Tracker</h5>
				</div>
				<div class="col-4 text-right">
					<button class="btn btn-primary" @click.prevent="addItem">Add Item</button>
				</div>
			</div>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col">
					Content
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'process-tracker',
	data() {
		return {
		}
	},
	methods: {
		addItem() {

		}
	}
}
</script>
<style scoped>
</style>
