<template>
<div>
	<div class="row">
		<div class="col-4 text-center p-3 m-0" v-for="(subPage, index) in subPages" :key="index" >
			<div class="p-0 btn btn-outline-primary" @click.prevent="goToPage(subPage.name)">
				<div class="row align-items-center m-0">
					<div class="col-4 bg-light p-0 rounded-left"><img class="img-fluid w-100 p-sm-3 p-md-3 p-lg-4" :src="subPage.image"></div>
					<div class="col-8 p-0">{{ subPage.name }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>

export default {
	name: 'crops-dashboard',
	props: {
		selectedCrop: Number
	},
	data() {
		return {
			subPages: [
				{
					name: 'Experts',
					image: './img/crops/experts.svg'
				},
				{
					name: 'Crop Varieties',
					image: './img/crops/crop_varieties.svg'
				},
				{
					name: 'Farm Practices',
					image: './img/crops/farm_practices.svg'
				},
				{
					name: 'Diseases',
					image: './img/crops/disease.svg'
				},
				{
					name: 'Nutrients',
					image: './img/crops/nutrition.svg'
				},
				{
					name: 'Market Price',
					image: './img/crops/market_price.svg'
				},
				{
					name: 'Fertilizers',
					image: './img/crops/fertilizer_pesticide.svg'
				},
				{
					name: 'Institutional Advisories',
					image: './img/crops/institutional_advisory.svg'
				},
				{
					name: 'Pesticides',
					image: './img/crops/fertilizer_pesticide.svg'
				},
				{
					name: 'Pests and Attacks',
					image: './img/crops/pests.svg'
				},
				{
					name: 'Govt Schemes',
					image: './img/crops/government_schemes.svg'
				},
				{
					name: 'Geographic Data',
					image: './img/crops/geographic_data.svg'
				},
				{
					name: 'Water Management',
					image: './img/crops/water_drops.svg'
				},
				{
					name: 'Soil Reports',
					image: './img/crops/soil_report.svg'
				},
				{
					name: 'Satellite images',
					image: './img/crops/satellite.svg'
				},
				{
					name: 'Meteorological Data',
					image: './img/crops/meteriological_data.svg'
				},
				{
					name: 'Crop Calendar',
					image: './img/crops/crop_calendar.svg'
				},
				{
					name: 'Misc',
					image: './img/crops/misc.svg'
				},
			]
		}
	},
	methods: {
		goToPage(page) {
			this.$router.push('/crops/' + page.toLowerCase().replaceAll(' ', '-'));
		}
	},
	mounted(){
		this.$store.dispatch("fetchCropList")
		.catch(error => {
					this.$notify({
						title: "Failed to fetch experts",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
	}
}
</script>
<style scoped>
.bg-dark {
	background-color: #002635;
}
</style>
