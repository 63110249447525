<template>
	<div>
		<div class="">
			<div class="container-fluid align-items-center">
				<div class="row mt-2">
					<div class="col">
						<h1 class="display-3">Orders</h1>
					</div>
				</div>
				<div class="row d-flex justify-content-start my-3">
					<div class="col-3">
						<select name="filter" id="filter" class="form-control" v-model="orderStatus" @change="handleStatus">
						<option value="">All</option>
							<option
									v-for="(status, idx) in optionsList"
									:key="idx"
									:value="status"
									>{{ status }}</option
								>
						</select>
					</div>
					
				</div>
			</div>
			<div class="my-3 px-lg-4">
				<div v-if="orderList.length>0" class="card p-2">
					<div class="row">
						<div class="col">
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="pagedOrdersList"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th
											v-for="(
												header, index
											) in tableHeaders"
											:key="index"
										>
											{{ header }}
										</th>
										<th>Status</th>
										<th class="pl-5">Action</th>
									</template>

									<template slot-scope="{ row, index }">
										<td>
											{{
												pageSize * (currentPage - 1) +
												(index + 1)
											}}
										</td>
										<td>{{ row.ordered_date && row.ordered_date.slice(0,10) }}</td>
										<td>{{ row.order_id }}</td>
										<td>{{ row.user_name }}</td>
										<td>{{ row.items }}</td>
										<td>{{ row.final_amount }}</td>
										<td>{{ row.user_mobile }}</td>
										<td
							class="text-center"
							v-if="!edit.status ||(edit.status && edit.index !== index)"
						>
							<span :class="row.order_status=='pending' ?'bg-orange text-white px-2 rounded ': row.order_status=='on_route' ?'bg-blue text-white px-2 rounded': row.order_status=='cancelled' ?'bg-danger text-white px-2 rounded':'bg-success text-white px-2 rounded'">{{ row.order_status }}</span>
						</td>
						<td v-if="edit.status && edit.index == index">
							<select
								class="form-control form-control-alternative"
								v-model="statusData.order_status"
							>
								<option
									v-for="(status, idx) in optionsList"
									:key="idx"
									:value="status"
									>{{ status }}</option
								>
							</select>
						</td>
						<td
							v-if="
								!edit.status ||
									(edit.status && edit.index !== index)
							"
						>
							<i
								class="btn fas fa-pen"
								@click.prevent="editItem(index, row)"
							></i>
							<i
								class="btn fas fa-eye"
								@click.prevent="viewItem(row.id)"
							></i>
						</td>
						<td v-if="edit.status && edit.index == index">
							<i
								class="btn fas fa-times text-danger"
								@click.prevent="editItem(null)"
							></i>
							<i
								class="btn fas fa-check text-success"
								@click.prevent="setStatus(row.id)"
							></i>
						</td>

									</template>
								</base-table>
							</div>
						</div>
					</div>
					<base-pagination
						class="mt-3 mr-3"
						:perPage="pageSize"
						:total="orderList.length"
						v-model="currentPage"
						align="center"
					></base-pagination>
				</div>
                <div v-else class="d-flex justify-content-center align-content-center align-items-center"><h2 class="text-warning">No Orders Found</h2></div>
			</div>
			<modal
			v-if="openModal"
			class="modal"
			:show="openModal"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="openModal = false"
			modal-classes=" modal-dialog-centered  modal-lg"
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Order Details</h3>
				</div>
			</template>
			<div class="mb-4">
			<div>
				<div class="row">
					<div class="col">
						<p>Order ID : {{orderDetails.order_id}}</p>
					</div>
					<div class="col">
						<p>Order Status :  <span :class="orderDetails.order_status=='pending' ?'bg-orange text-white px-2 rounded ': orderDetails.order_status=='on_route' ?'bg-blue text-white px-2 rounded': orderDetails.order_status=='cancelled' ?'bg-danger text-white px-2 rounded':'bg-success text-white px-2 rounded'">{{orderDetails.order_status}}</span></p>
					</div>
				</div>
				<div class="row">
				<div class="col">
				<p>Ordered Date : {{orderDetails && orderDetails.ordered_date.slice(0,10)}}</p>
				</div>
				<div class="col">
				<p>Amount : ₹ {{orderDetails.final_amount}}</p>
				</div>
				</div>			
				<p>Address :  {{orderDetails.user_address}}</p>
				<h4>Ordered Items</h4>
				<div class="row">
				
				<div class="card col custom-card shadow-lg p-3 mb-3" v-for="(item,idx) in orderDetails.ordered_items" :key="idx">
				<div class=" d-flex justify-content-start">
				<img class="custom-image rounded" :src="productImage(item.product.image)" alt="">
				<div class="ml-4 data">
					<p class="m-0">Product Name : {{item.product.name}}</p>
					<p class="m-0">Price : {{item.price}}</p>
					<p class="m-0">Quantity : {{item.quantity}}</p>
				</div>
				</div>
				</div>
				</div>
			</div>
			</div>
			<template v-slot:footer>
				<base-button
					type="danger pl-4 pr-4 "
					class="mb-2 btn btn-sm"
					@click.prevent="openModal = false"
					>Cancel</base-button
				>
			</template>
		</modal>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	name: "ecommerce dashboard",

	props: {},
	data() {
		return {
			displayLoader: false,
			orderStatus:'',
			pageSize: 10,
			currentPage: 1,
			openModal:false,
			edit: {
				index: null,
				status: false
			},
			statusData: {
				id: "",
				order_status: ""
			},
			optionsList: ["pending", "on_route", "delivered", "cancelled"],
			orderDetails:{}

		};
	},
	methods: {
		goToPage(page) {
			this.$router.push(
				"/ecommerce-section/" + page.toLowerCase().replaceAll(" ", "-")
			);
		},
		productImage(image) {
			return `https://${image}`
		},
        fetchOrdersList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchOrdersList",this.orderStatus)
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch order list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		setStatus(id) {
			var error = undefined;

			if (this.statusData.order_status.trim() == "") {
				error = "Please select a valid status";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.statusData.id = id;
			this.$store
				.dispatch("setOrderDetails", {
					data: this.statusData
				})
				.then(() => {
					this.fetchOrdersList();
					this.editItem(null);
				})
				.catch(err => {
					this.$notify({
						title: "Failed to update status",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		editItem(index, row) {
			this.edit.index = index;
			this.edit.status = !this.edit.status;
			if (row) {
				this.statusData.order_status = row.order_status;
			}
		},
		viewItem(id) {
			var orderID = id;
			this.displayLoader = true;
			this.$store
				.dispatch("fetchOrderDetails",orderID)
				.then(() => {
					this.orderDetails=JSON.parse(
						JSON.stringify(this.$store.state.EcommerceData.Orders.orderDetails));
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch order details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
			this.openModal = true;
		},
		handleStatus() {
			this.fetchOrdersList()
		}

	},
	computed: {
		orderList() {
			return this.$store.state.EcommerceData.Orders.ordersList;
		},
		tableHeaders() {
			return this.$store.state.EcommerceData.Orders.orderHeaders;
		},
		pagedOrdersList() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.orderList.slice(start, start + this.pageSize);
		},
	},
    mounted() {
		this.fetchOrdersList()
    },
};
</script>
<style scoped>
.custom-image {
	height: 6rem;
	width: 6rem;
	border: 1px solid gray;
}
.custom-card {
	padding: 10px;
	margin: 10px;
	border: 1px solid #cfd2cf;
}
</style>
