<template>
<div>
	<base-header class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center">
		<!-- Mask -->
		<span class="mask bg-gradient-success opacity-8"></span>
		<!-- Header container -->
		<div class="container-fluid align-items-center">
			<div class="row">
				<div class="col">
					<h1 class="display-2 text-white">Data Management</h1>
					<p class="text-white mt-0 mb-0">Manage all data here</p>
					<ul class="nav mt-4 align-items-center">
						<li class="nav-item form-control-label mr-4 pr-2">
							<span class="">Filter by: </span>
						</li>
						<li class="nav-item pl-2 pr-2">
							<select class="form-control" style="width:190px" v-model="filter.type">
								<option>All</option>
								<option :value="type" v-for="(type, index) in $store.state.dataManagementData.types" :key="index">
									{{ type }}
								</option>
							</select>
						</li>
						<!-- <li class="nav-item form-control-label ml-5 pl-1 mr-3">
							<span class="">Order: </span>
						</li>
						<li class="nav-item pl-3 ml-4 pr-2">
							<select class="form-control" v-model="filter.order">
								<option>Newest first</option>
								<option>Oldest first</option>
							</select>
						</li> -->
					</ul>
					<ul class="nav mt-4 align-items-center">
						<li class="nav-item form-control-label">
							<span class="">Filter by user: </span>
						</li>
						<li class="nav-item pl-2 pr-2">
							<input class="form-control" type="text" placeholder="User name here" v-model="filter.username" />
						</li>
					</ul>
					<ul class="nav mt-4 align-items-center">
						<li class="nav-item form-control-label mr-3">
							<span class="">Start Date: </span>
						</li>
						<li class="nav-item pl-2 pr-2">
							<input class="form-control" style="width:190px" type="date" v-model="filter.startDate" />
						</li>
						<li class="nav-item form-control-label ml-4 pl-2 mr-4">
							<span class="">End Date: </span>
						</li>
						<li class="nav-item pl-2 pr-2">
							<input class="form-control" type="date" style="width:190px" v-model="filter.endDate" />
						</li>
					</ul>
					
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt--7">
		<div class="row">
			<div class="col">
				<data-management-table title="Data Management"
					type="light"
					:filterType="filter.type"
					:filterStartDate="filter.startDate"
					:filterEndDate="filter.endDate"
					:filterUsername="filter.username">
				</data-management-table>
			</div>
		</div>
	</div>

</div>
</template>
<script>
import DataManagementTable from './DataManagementTable'

export default {
	name: 'data-management',
	data() {
		return {
			
			filter: {
				type: 'All',
				startDate: null,
				endDate:null,
				username: '',
			}
		}
	},
	components: {
		DataManagementTable
	}
};
</script>
<style></style>
