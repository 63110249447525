<template>
	<div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
		<div
			class="card-header border-0"
			:class="type === 'dark' ? 'bg-transparent' : ''"
		>
			<div class="row align-items-center">
				<div class="col">
					<h3
						class="mb-0"
						:class="type === 'dark' ? 'text-white' : ''"
					>
						{{ title }}
					</h3>
				</div>
				<div class="col text-right">
					<base-button
						type="primary"
						size="sm"
						@click.prevent="toggleDataAddDialog"
						>Add</base-button
					>

					<modal
						:show="showDataAddDialog"
						type="mini"
						@close="toggleDataAddDialog"
					>
						<template v-slot:header>
							Add data
						</template>
						<div class="text-left">
							<div class="row mt-1 mb-3">
								<div class="col-4 align-self-center">
									File:
								</div>
								<div class="col-8">
									<input
										@change="onFileUploaded"
										class="form-control"
										type="file"
										ref="fileUploader"
									/>
								</div>
							</div>
							<div class="row mt-1 mb-3">
								<div class="col-4 align-self-center">
									Data Name:
								</div>
								<div class="col-8">
									<input
										class="form-control"
										type="text"
										v-model="itemData.data_name"
									/>
								</div>
							</div>
							<div class="row mt-1 mb-3">
								<div class="col-4 align-self-center">
									Data Description:
								</div>
								<div class="col-8">
									<input
										class="form-control"
										type="text"
										v-model="itemData.data_description"
									/>
								</div>
							</div>
							<div class="row mt-1 mb-3">
								<div class="col-4 align-self-center">
									Access Level:
								</div>
								<div class="col-8">
									<select
										v-model="itemData.access_level"
										class="form-control"
									>
										<option
											v-for="(accessLevel,
											index) in accessLevels"
											:key="index"
											class="form-control"
											>{{ accessLevel }}</option
										>
									</select>
								</div>
							</div>
							<div class="row mt-1 mb-3">
								<div class="col-4 align-self-center">
									Type:
								</div>
								<div class="col-8">
									<select
										v-model="itemData.data_type"
										class="form-control"
									>
										<option
											v-for="(type, index) in types"
											:key="index"
											class="form-control"
											>{{ type }}</option
										>
									</select>
								</div>
							</div>
							<!-- removing v-if for now, untill further information -->
							<!-- v-if="itemData.data_type == 'farmer_data'" -->
							<div
								class="row mt-1 mb-3"
							>
								<div class="col-4 align-self-center">
									Tag a farmer:
								</div>
								<div class="col-8">
									<select
										v-model="itemData.farmer"
										class="form-control"
									>
										<option
											v-for="farmer in farmerList"
											:key="farmer.id"
											:value="farmer.id"
											class="form-control"
											>{{ farmer.name }}</option
										>
									</select>
								</div>
							</div>
						</div>
						<template v-slot:footer>
							<button
								class="btn btn-success"
								@click.prevent="insertData"
							>
								Add
							</button>
						</template>
					</modal>
				</div>
			</div>
		</div>

		<div class="table-responsive">
			<base-table
				class="table align-items-center table-flush"
				:class="type === 'dark' ? 'table-dark' : ''"
				:thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
				tbody-classes="list"
				:data="pagedTableData"
			>
				<template slot="columns">
					<th>Data Name</th>
					<th>Uploaded By</th>
					<th>Date</th>
					<th>Access Level</th>
					<th>Type</th>
					<th></th>
				</template>

				<template slot-scope="{ row, index }">
					<td>
						{{ row.data_name }}
					</td>
					<td>
						{{ row.uploadedby }}
					</td>
					<td>
						{{ row.added_on.slice(0, 10) }}
					</td>
					<td>
						{{ row.access_level }}
					</td>
					<td>
						{{ row.data_type }}
					</td>

					<td class="text-right">
						<base-dropdown class="dropdown" position="right">
							<a
								slot="title"
								class="btn btn-sm btn-icon-only text-light"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<i class="fas fa-ellipsis-v"></i>
							</a>

							<template>
								<a class="dropdown-item">Download</a>
								<a
									class="dropdown-item text-danger"
									@click.prevent="deleteData(index)"
									>Delete</a
								>
							</template>
						</base-dropdown>
					</td>
				</template>
			</base-table>
		</div>

		<div
			class="card-footer d-flex justify-content-end"
			:class="type === 'dark' ? 'bg-transparent' : ''"
		>
			<base-pagination
				:perPage="pageDataCount"
				:total="tableData.length"
				v-model="currentPage"
			></base-pagination>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	name: "data-management-table",
	props: {
		type: {
			type: String
		},
		filterType: {
			type: String
		},
		title: {
			type: String
		},
		filterUsername: {
			type: String
		},
		filterStartDate: {
			type: String
		},
		filterEndDate: {
			type: String
		}
	},
	data() {
		return {
			displayLoader:false,
			filteredIDs: [],
			pageDataCount: 10,
			currentPage: 1,
			showDataAddDialog: false,
			addData: {
				file: "",
				name: "",
				description: "",
				accessLevel: "",
				type: "",
				farmerTag: ""
			},
			itemData: {
				data_name: "",
				data_description: "",
				access_level: "",
				data_type: "",
				farmer: 4
			}
		};
	},
	methods: {
		toggleDataAddDialog() {
			this.resetAddDataParams();
			this.showDataAddDialog = !this.showDataAddDialog;
			this.pageCount = 1;
		},
		onFileUploaded(e) {
			var files = e.target.files;
			if (!files) {
				return;
			}

			this.addData.file = files[0];
		},
		insertData() {
			if (this.inputHasErrors()) {
					return;
				}
			this.displayLoader = true;
			// Add new action params
			var actionString = "add";
			var actionObject = {
				itemData: this.itemData
			};

			this.$store
				.dispatch("setDataDetails", {
					actionObject: actionObject
				})
				.then(() => {
					this.toggleDataAddDialog();
				})
				.catch(error => {
					this.$notify({
						title: "Failed to " + actionString + " data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
			// var file = this.addData.file;
			// var name = this.addData.name.trim();
			// var description = this.addData.description.trim();
			// var accessLevel = this.addData.accessLevel;
			// var type = this.addData.type;
			// var farmerTag = ((type == 'Farmer Data') ? (this.addData.farmerTag) : (''));

			// var dateSplit = new Date().toString().slice(4, 15).split(' ');
			// var date = dateSplit[1] + ' ' + dateSplit[0] + ' ' + dateSplit[2];

			// if (!file) {
			// 	file = undefined;
			// }
			// if (name == '') {
			// 	name = 'Unknown';
			// }

			// var user = this.$store.state.accountData.username;
			// var id = this.tableData.length + 1;

			// // Unshift = insert at start
			// this.tableData.unshift({
			// 	id: id,
			// 	file: file,
			// 	dataName: name,
			// 	dataDescription: description,
			// 	uploadedby: user,
			// 	date: date,
			// 	accesslevel: accessLevel,
			// 	type: type,
			// 	farmerTag: farmerTag,
			// });

			
			// this.filterData();
		},
		resetAddDataParams() {
			this.addData.file = "";
			this.addData.accessLevel = this.accessLevels[0];
			this.addData.type = this.types[0];
			/* this.$refs.fileUploader.value = ''; */
		},
		deleteData(index) {
			this.tableData.splice(index, 1);
		},
		// filterData() {
		// 	// Super slow and inefficient, but works

		// 	if (this.filterOrder == "Oldest first") {
		// 		this.tableData.sort(function(a, b) {
		// 			// Slow, but does the job
		// 			return new Date(a.date) - new Date(b.date);
		// 		});
		// 	} else {
		// 		this.tableData.sort(function(a, b) {
		// 			// Slow, but does the job
		// 			return new Date(b.date) - new Date(a.date);
		// 		});
		// 	}

		// 	// this.filteredIDs = [];
		// 	// this.tableData.forEach(tableData => {
		// 	// 	var date = new Date(tableData.date);
		// 	// 	var start = new Date(this.filterStartDate);
		// 	// 	var end = new Date(this.filterEndDate);
		// 	// 	start.setHours(0, 0, 0, 0);
		// 	// 	end.setHours(0, 0, 0, 0);

		// 	// 	if (
		// 	// 		(tableData.type == this.filterType ||
		// 	// 			this.filterType == "All") &&
		// 	// 		tableData.uploadedby &&
		// 	// 			tableData.uploadedby.includes(this.filterUsername) &&
		// 	// 		date >= start &&
		// 	// 		date <= end
		// 	// 	) {
		// 	// 		this.filteredIDs.push(tableData.id);
		// 	// 	}
		// 	// });
		// },
		fetchDataList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchDataList",{startDate:this.filterStartDate,endDate:this.filterEndDate,searchQuery:this.filterUsername})
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch data list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
		fetchFarmerList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerList")
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch farmer list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
		filterDataType(){
			if(this.filterType !='All'){
			let data=this.tableData.filter(item=>item.data_type==this.filterType);
			return data
			}else{
				return this.tableData
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.data_name == "") {
				invalidValue = true;
			}
			if (this.itemData.data_description == "") {
				invalidValue = true;
			}
			if (this.itemData.access_level == "") {
				invalidValue = true;
			}
			if (this.itemData.data_type == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
	},
	computed: {
		accessLevels() {
			return this.$store.state.dataManagementData.accessLevels;
		},
		types() {
			return this.$store.state.dataManagementData.types;
		},
		tableData() {
			return this.$store.state.dataManagementData.tableData;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageDataCount;
			return this.filterDataType().slice(start, start + this.pageDataCount);
		},
		farmerList() {
			return this.$store.state.farmerData.farmerList;
		}
	},
	watch: {
		filterType() {
			this.filterDataType()
		},
		filterUsername() {
			this.fetchDataList()
		},
		filterStartDate() {
			this.fetchDataList()
		},
		filterEndDate() {
			this.fetchDataList()
		}
	},
	mounted() {
		this.fetchDataList();
		this.fetchFarmerList();
		this.resetAddDataParams();
		// this.filterData();
	}
};
</script>
<style></style>
