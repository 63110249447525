<template>
  <div>
    <div class="row">
      <div class="col">
        <div slot="header">
          <div class="row">
            <div class="col-12">
              <h1>Expert - {{ actionString }}</h1>
            </div>
          </div>
        </div>
        <div class="pl-lg-4 mt-4">
          <div class="row">
            <div class="col">
              <base-input
                label="Name"
                input-classes="form-control"
                v-model="itemData.name"
                :disabled="viewOnly"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input
                label="Phone No"
                input-classes="form-control"
                v-model="itemData.mobile"
                type="text"
                :disabled="viewOnly"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input
                label="Email"
                input-classes="form-control"
                v-model="itemData.email"
                type="email"
                :disabled="viewOnly"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="form-control-label"> Department</label>
            </div>
            <div class="col-12 form-group">
              <select
                class="rounded p-2 w-100 m-0 form-control custom-select"
                v-model="itemData.department"
                :disabled="viewOnly"
              >
                <option v-for="(department, index) in departments" :key="index" :value="department.value">
                  {{ department.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="button-bar text-right mt-4">
          <button
            class="btn btn-danger"
            @click.prevent="goBackToParent"
          >
            Cancel
          </button>
          <button class="btn btn-success" @click.prevent="performAction">
            Done
          </button>
        </div>
      </div>
    </div>
    <loader :displayLoader="displayLoader"></loader>
  </div>
</template>
<script>
export default {
  name: "experts-add",
  props: {
    editItem: {
      type: Object,
      default: undefined,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayLoader: false,
      select: "Select",
      itemData: {
        name: "",
        mobile: "",
        email: "",
        department:"",
      },
    };
  },
  methods: {
    performAction() {
      if (!this.viewOnly) {
        if (this.inputHasErrors()) {
          return;
        }

        this.displayLoader = true;
        // Add new action params
        var actionString = 'add';
        var actionObject = {
          expertData: this.itemData
        }
        if (this.editItem && this.editItem.index > -1) {
          // Edit existing action params
          actionString = 'edit';
          actionObject = {
            expertData: this.itemData,
            index: this.editItem.index,
          };
        }

        this.$store.dispatch("setExpertDetails", actionObject)
          .then(() => {
            this.goBackToParent()
          }).catch((error) => {
            this.$notify({
              title: "Failed to " + actionString + " expert",
              icon: "fa fa-exclamation-triangle",
              type: "danger",
              message: error.toString(),
            });
          }).then(() => {
            this.displayLoader = false;
          });
      } else {
        this.goBackToParent();
      }
    },
    goBackToParent() {
      this.$router.back();
    },
    watchBullets() {
      // Fragile lol
      var str = this.itemData.details;
      if (str[str.length - 1] == "\n" || str.length == 0) {
        this.itemData.details += "• ";
      }
    },
    inputHasErrors() {
      var invalidValue = false;

      this.itemData.name = this.itemData.name.trim();
      if (this.itemData.name == "") {
        invalidValue = true;
      }
      this.itemData.email = this.itemData.email.trim();
      if (this.itemData.email == "") {
        invalidValue = true;
      }
      this.itemData.mobile = this.itemData.mobile.trim();
      if (this.itemData.mobile == "") {
        invalidValue = true;
      }
      
      if (invalidValue) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: "Please enter valid data for all fields",
        });
      }

      return invalidValue
    },
  },
  computed: {
    expertsData() {
      return this.$store.state.cropData.experts
    },
    departments() {
      return this.expertsData.departments;
    },
    tableData() {
      return this.expertsData.tableData;
    },
    actionString() {
      if (this.viewOnly) {
        return "View";
      } else if (this.editItem) {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },

  mounted() {
    if (this.editItem) {
      this.itemData = this.editItem.data;
      this.editItemIndex = this.editItem.index;
    }
  },
};
</script>
<style scoped></style>
