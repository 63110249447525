<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header" class="border-0">
					<div class="row align-items-center">
						<div class="col">
							<h3 class="mb-0">
								{{ actionString }} Crop Variety
							</h3>
						</div>
					</div>
				</div>

				<h6 class="heading-small text-muted mb-4">Crop Varieties</h6>
				<div class="pl-lg-4">
					<div class="row">
						<div class="col-12">
							<base-input
								alternative=""
								label="Name of Variety"
								input-classes="form-control"
								v-model="varietyData.variety"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label"> Details </label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets(1)"
								v-model="varietyData.details"
								:disabled="viewOnly"
							></textarea>
						</div>
						<div class="col-12 form-group">
							<label class="form-control-label">
								Speciality
							</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets(2)"
								v-model="varietyData.speciality"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						v-if="!viewOnly"
						@click.prevent="$router.back()"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>

				<loader :displayLoader="displayLoader"></loader>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "add-crop-varieties",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			displayLoader: false,
			itemData: ["", "• ", "• "],
			varietyData: {
				crop: "",
				variety: "",
				details: "",
				speciality: "",
				is_improved_variety: true
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}
				// var invalidValue = false;
				// this.varietyData.forEach((element, index) => {
				// 	if (index >= 3) {
				// 		return;
				// 	}

				// 	if (this.varietyData[index] == undefined) {
				// 		invalidValue = true;
				// 	} else {
				// 		this.varietyData[index] = element.trim();
				// 		if (this.varietyData[index] == "") {
				// 			invalidValue = true;
				// 		}
				// 	}
				// });

				// if (invalidValue) {
				// 	this.$notify({
				// 		title: 'Error',
				// 		icon: 'fa fa-exclamation-triangle',
				// 		type: 'danger',
				// 		message: 'Please enter valid data for all fields'
				// 	});
				// 	return;
				// }

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					cropVarietyData: this.varietyData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						cropVarietyData: this.varietyData,
						index: this.editItem.index
					};
				}

				this.$store
					.dispatch("setCropVarietyDetails", actionObject)
					.then(() => {
						this.$router.back();
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " crop variety",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.$router.back();
			}
		},
		inputHasErrors() {
			var invalidValue = false;

			this.varietyData.variety = this.varietyData.variety.trim();
			if (this.varietyData.variety == "") {
				invalidValue = true;
			}
			this.varietyData.details = this.varietyData.details.trim();
			if (this.varietyData.details == "") {
				invalidValue = true;
			}
			this.varietyData.speciality = this.varietyData.speciality.trim();
			if (this.varietyData.speciality == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets(index) {
			// Fragile lol
			let str = this.itemData[index];
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData[index] += "• ";
			}
		},
		selectCrop() {
			this.varietyData.crop = this.selectedCrop
		},
		goBackToParent() {
			this.$router.back();
		},
	},
	computed: {
		varietiesData() {
			return this.$store.state.cropData.cropVarieties;
		},
		tableData() {
			return this.varietiesData.tableData;
		},
		table() {
			return this.$store.state.crops.cropVarieties.data;
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
		}
	},
	mounted() {
		if (this.editItem) {
			this.varietyData = this.editItem.data;
		}
		this.selectCrop();
		if(this.selectedCrop === undefined){
			this.goBackToParent()
		}
	}
};
</script>
<style scoped></style>
