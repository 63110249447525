<template>
	<div>
		
		<div class="container-fluid align-items-center">
			<div class="row">
				<div class="col">
					<h1 class="display-2">Images</h1>
                    
				</div>
			</div>
            <div class="row mt-3">
                <div class="col-2">
                    <select @change="handleCrop()" v-model="crop" class="form-control" name="" id="">
                        <option disabled  value="">Choose Crop</option>
						<option v-for="(crop) in cropList" :key="crop.id" :value="crop.id">{{crop.crop}}</option>
                    </select>
                </div>
				<div class="col-2">
                    <select @change="handleCropVariety()"  v-model="cropVariety" class="form-control" name="" id="">
                        <option disabled  value="">Choose Crop Variety</option>
						<option v-for="(cropVariety) in cropVarietyList" :key="cropVariety.id" :value="cropVariety.id">{{cropVariety.variety}}</option>
                    </select>
                </div>
                <div class="col-2">
                    <select @change="handleCropPart()"  v-model="cropPart" class="form-control" name="" id="">
                        <option disabled value="">Choose Crop Part</option>
						<option v-for="(cropPart,idx) in cropPartList" :key="idx" :value="cropPart.id">{{cropPart.part_name}}</option>
                    </select>
                </div>
                
                <div class="col ">
                    <button class="btn btn-success float-right" @click.prevent="download_btn" download >Download</button>
                </div>
            </div>
			<div class="row mt-5" v-if="imageData.length > 0">
				<div class="col-3 pb-4 pt-3" v-for="(pages,index) in imageData" :key="index + 'page'" >
                   <div class="p-0 " >
				<div class="card custom-card"   >
						<img class="custom-image" :src="pages.image" alt="" @click.prevent="handleImage(pages)">
						
						<div class="mt-3 d-flex justify-content-between" >
							<div class="card-title  px-4 bg-danger custom-title ml-3 "><span class="">{{pages.crop}}</span></div>
							<div class="card-title  px-4 bg-warning custom-title mr-3"><span>{{pages.crop_variety}}</span></div>
						</div>
                        <div class=" d-flex justify-content-between" >
							<div class="card-title px-4 bg-info ml-3  custom-title"><span>{{pages.crop_part}}</span></div>
							<button class="btn btn-success btn-sm mr-3 " style="height:25px;border-radius:none" @click.prevent="downloadImage(pages.image)">Download</button>
						
						</div>
					</div>
                   </div>
				</div>
			</div>
			<div v-else>
				<h1 class="mt-6 mb-9 pb-7 text-center">No images found</h1>
			</div>
			
		</div>
		<div>
		<CoolLightBox :items="images" :index="index" @close="index = null"></CoolLightBox>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>	
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox';
import axios from 'axios';
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
	name: "images",
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
	},
	components:{
		CoolLightBox,
	},
	data() {
		return {
			displayLoader: false,
			images:[],
			index: null,
			crop: "",
			cropPart: "",
			cropVariety:""
		};
	},
	computed: {
		imageData() {
			return this.$store.state.ExecutiveData.ImageData.images;
		},
		cropList() {
			return this.$store.state.ExecutiveData.ImageData.farmerCropList;
		},
		cropPartList() {
			return this.$store.state.ExecutiveData.ImageData.cropPart;
		},
		cropVarietyList() {
			return this.$store.state.ExecutiveData.ImageData.cropVariety
		}
	},
	methods:{
		handleImage(data){
			this.images=[];
			this.images.push(data.image);
			this.index=0;
		},
		downloadImage(image) {
			axios({
                    url: image,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fURL = document.createElement('a');
    
                     fURL.href = fileURL;
                     fURL.setAttribute('download', 'file.jpg');
                     document.body.appendChild(fURL);
    
                     fURL.click();
                });
		},
		download_btn() {
			let images = this.imageData.map(item => item.image);
			console.log(images)
		},
		fetchFarmerImageList(cropData) {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerImageList", { farmerID: this.farmerID, crop: cropData.crop, cropPart: cropData.cropPart, cropVariety: cropData.cropVariety })
				.then(() => {
				this.displayLoader = false;
			})
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch images",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
				this.displayLoader = false;
			});
		},
		fetchFarmerCropLists() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerCropListData", this.farmerID)
				.then(() => {
				this.displayLoader = false;
			})
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch farmer crops",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
				this.displayLoader = false;
			});
		},
		fetchFarmerCropPartList() {
			this.displayLoader=true
			this.$store
				.dispatch("fetchFarmerCropPartList")
			.then(() => {
				this.displayLoader = false;
			})
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch farmer crops parts",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
				this.displayLoader = false;
			});
		},
		handleCrop() {
			this.displayLoader=true
			this.$store
				.dispatch("fetchFarmerCropVarietyList", this.crop)
			.then(() => {
				this.displayLoader = false;
			})
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch farmer crops variety",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
				this.displayLoader = false;
			});
			this.fetchFarmerImageList({crop:this.crop,cropPart:"",cropVariety:""});
		},
		handleCropVariety() {
			this.fetchFarmerImageList({crop:"",cropPart:this.cropPart,cropVariety:""});
			
		},
		handleCropPart() {
			this.fetchFarmerImageList({crop:this.crop,cropPart:"",cropVariety:this.cropVariety});
		}
	},
	mounted() {
		this.fetchFarmerImageList({crop:"",cropPart:"",cropVariety:""});
		this.fetchFarmerCropLists();
		this.fetchFarmerCropPartList()
		
	}
};
</script>
<style scoped>
.custom-card {
    width: 18rem;
    border-radius: 20px;
    box-shadow: 6px 6px 5px lightblue;

}
.custom-image{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 1px solid black;
	cursor: pointer;
	width: 288px;
	height: 310px;
}
.custom-title{
    border-radius: 5px !important;
	color:white !important;
}
.cus-button{
border-radius: 20px;
}
</style>
