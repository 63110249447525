<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header" class="border-0">
					<div class="row align-items-center">
						<div class="col">
							<h3 class="mb-0">{{ actionString }} Item</h3>
						</div>
					</div>
				</div>

				<h6 class="heading-small text-muted mb-4">
					Fertilizers
				</h6>
				<div class="pl-lg-4">
					<div class="row">
						<div class="col-lg-6">
							<base-input
								alternative=""
								label="Name"
								input-classes="form-control"
								v-model="fertData.name"
								:disabled="viewOnly"
							/>
						</div>
						<div class="col-lg-6">
							<base-input
								alternative=""
								label="Reason for application"
								input-classes="form-control"
								v-model="fertData.reason_for_application"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label">
								Method of application
							</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets(2)"
								v-model="fertData.method_of_application"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label"> Details </label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets(3)"
								v-model="fertData.details"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						v-if="!viewOnly"
						@click.prevent="$router.back()"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>
			</div>

			<loader :displayLoader="displayLoader"></loader>
		</div>
	</div>
</template>
<script>
export default {
	name: "add-fertlizer",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			displayLoader: false,
			itemData: ["", "", "• ", "• "],
			fertData: {
				crop: "",
				name: "",
				reason_for_application: "",
				method_of_application: "",
				details: ""
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				// var invalidValue = false;
				// this.itemData.forEach((element, index) => {
				// 	if (index >= 4) {
				// 		return;
				// 	}

				// 	if (this.itemData[index] == undefined) {
				// 		invalidValue = true;
				// 	} else {
				// 		this.itemData[index] = element.trim();
				// 		if (this.itemData[index] == "") {
				// 			invalidValue = true;
				// 		}
				// 	}
				// });

				// if (invalidValue) {
				// 	this.$notify({
				// 		title: "Error",
				// 		icon: "fa fa-exclamation-triangle",
				// 		type: "danger",
				// 		message: "Please enter valid data for all fields",
				// 	});
				// 	return;
				// }

				this.displayLoader = true;
				if (this.editItem != undefined) {
					// Edit existing
					let actionObject = {
						fertData: this.fertData
					};
					this.$store
						.dispatch("setFertilizerDetails", actionObject)
						.then(() => {
							this.$router.back();
							this.displayLoader = false;
						})
						.catch(error => {
							this.$notify({
								title: "Failed to edit fertilizer",
								icon: "fa fa-exclamation-triangle",
								type: "danger",
								message: error.toString()
							});
							this.displayLoader = false;
						});
				} else {
					// Add new
					let actionObject = {
						fertData: this.fertData
					}
					this.$store
						.dispatch("setFertilizerDetails", actionObject )
						.then(() => {
							this.$router.back();
							this.displayLoader = false;
						})
						.catch(error => {
							this.$notify({
								title: "Failed to add fertilizer",
								icon: "fa fa-exclamation-triangle",
								type: "danger",
								message: error.toString()
							});
							this.displayLoader = false;
						});
				}
			} else {
				this.$router.back();
			}
		},
		watchBullets(index) {
			// Fragile lol
			var str = this.itemData[index];
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData[index] += "• ";
			}
		},
		inputHasErrors() {
			var invalidValue = false;

			this.fertData.name = this.fertData.name.trim();
			if (this.fertData.name == "") {
				invalidValue = true;
			}
			this.fertData.reason_for_application = this.fertData.reason_for_application.trim();
			if (this.fertData.reason_for_application == "") {
				invalidValue = true;
			}
			this.fertData.method_of_application = this.fertData.method_of_application.trim();
			if (this.fertData.method_of_application == "") {
				invalidValue = true;
			}
			this.fertData.details = this.fertData.details.trim();
			if (this.fertData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		selectCrop() {
			this.fertData.crop = this.selectedCrop
		}
	},
	computed: {
		tableData() {
			return this.$store.state.crops.fertilizers.data;
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
		}
	},
	mounted() {
		if(this.selectedCrop === undefined){
			this.$router.back()
		}
		if (this.editItem) {
			this.fertData = this.editItem.data;
		}
		this.selectCrop();
	}
};
</script>
<style scoped></style>
