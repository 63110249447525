<template>
	<div>
		<base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
			<!-- Card stats -->
			<div class="row">
				<div
					v-for="(headerCard, index) in headerCardData"
					class="col-xl-4 col-lg-6"
					:key="index"
				>
					<header-card
						:headerCardData="headerCard"
						class="mb-4 mb-xl-0"
					></header-card>
				</div>
			</div>
		</base-header>
		
		<div class="container-fluid ">
			<h1 class="text-center py-4">{{token==='admin'?'Task':'Attendance'}}</h1>
			<div class="card shadow  mb-5">
						<div class="row">
							<div class="col">
								<div class="table-responsive">
									<base-table
										class="table align-items-center table-flush"
										title=""
										thead-classes="thead-light"
										tbody-classes="list"
										type="hover"
										:data="pagedTableData"
									>
										<template slot="columns">
											<th>Sl No</th>
											<th
												v-for="(
													header, index
												) in tableHeaders()"
												:key="index"
											>
												{{ header }}
											</th>
											<th v-if="token==='admin'" class="pl-6">Actions</th>
										</template>

										<template slot-scope="{ row, index }">
											<td>{{ index + 1 }}</td>
											<td v-if="token==='admin'">{{ row.task_date }}</td>
											<td v-else>{{row.start_time && row.start_time.slice(0,10)}}</td>
											<td v-if="token==='executive'">{{row.start_time && row.start_time.slice(11,19)}}</td>
											<td v-if="token==='admin'">
												{{ row.task_name}}
											</td>
											<td v-else>{{row.end_time && row.end_time.slice(11,19)}}</td>
											<td v-if="token==='admin'">{{ row.executive}}</td>
											<td v-else>{{row.duration && row.duration.slice(0,7) }} hrs</td>
											<td v-if="token==='admin'">
												<button class="btn btn-sm btn-success" @click.prevent="approveTask(row)">Approve </button>	
												<button class="btn btn-sm btn-danger" @click.prevent="deleteItemIndex =tableData().indexOf(pagedTableData[index])">Decline </button>											
											</td>
										</template>
									</base-table>
								</div>
							</div>
						</div>
						<base-pagination
							class="mt-3 mr-3"
							:perPage="pageSize"
							:total="tableData.length"
							v-model="currentPage"
							align="center"
						></base-pagination>
		
					</div>
			<!-- <div class="row">
				<div class="col-xl-6 mb-5 mb-xl-0">
					<line-chart-view
						type="default"
						:chartData="chartData[0]"
					></line-chart-view>
				</div>

				<div class="col-xl-6">
					<line-chart-view
						:chartData="chartData[1]"
					></line-chart-view>
				</div>
			</div>

			<div class="row mt-5">
				<div class="col-xl-6 mb-5 mb-xl-0">
					<line-chart-view
						:chartData="chartData[2]"
					></line-chart-view>
				</div>

				<div class="col-xl-6">
					<line-chart-view
						type="default"
						:chartData="chartData[3]"
					></line-chart-view>
				</div>
			</div> -->
			<delete-dialog
			v-if="token ==='admin'"
				:data="tableData()"
				v-model="deleteItemIndex"
				actionString="declineTask"
				item="task"
				message="Decline"
			>
				<span slot="warning">This data will be removed!</span>
			</delete-dialog>
		</div>
	</div>
</template>
<script>
// import * as chartConfigs from "@/components/Charts/config";
import DeleteDialog from "./ItemDeleteDialog";
export default {
	name: "dashboard",
	components: {
		DeleteDialog,
	},
	data() {
		return {
			pageSize: 15,
			currentPage: 1,
			deleteItemIndex:-1,
			adTableHeaders:["Task Date", "Task Name", "Executive"],
			exeTableHeaders: ["Date", "Check in", "Check out", "Duration"],
			taskData:{
				is_verified:true
			},
			headerCardData: [
				{
					title: "Farmers",
					content: "970",
					icon: "fa fa-user",
					iconGradient: "gradient-orange",
					changePercent: "1%",
					changePositive: true,
				},
				{
					title: "Executives",
					content: "...",
					icon: "fa fa-user",
					iconGradient: "gradient-green",
					changePercent: "0%",
					changePositive: true,
				},
				
			],
			// chartData: [
				
				
				
			// 	{
			// 		title: "Title",
			// 		subTitle: "Subtitle",
			// 		chartData: {
			// 			labels: ["l1", "l2", "l3", "l4", "l5"],
			// 			datasets: [
			// 				{
			// 					label: "label",
			// 					data: [10, 20, 30, 20, 10],
			// 				},
			// 			],
			// 		},
			// 		extraOptions: chartConfigs.blueChartOptions,
			// 	},
			// ],
		};
	},
	computed: {

		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData().slice(start, start + this.pageSize);
		},
		token(){
			return localStorage.getItem('decoded-token');
		}
	},
	methods: {
		tableData(){
			if(this.token === 'admin'){
				return  this.$store.state.DashboardData.taskList;
			}else{
				return   this.$store.state.DashboardData.attendanceList;
			}
		},
		tableHeaders(){
			if(this.token === 'admin'){
			return this.adTableHeaders
			}else {
			return this.exeTableHeaders;
			}
		},
		fetchDashboardTaskList(){
		this.displayLoader = true;
		this.$store.dispatch('fetchDashboardTaskList')
		.then(() => {this.displayLoader = false;})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch dashboard task ",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
		},
		approveTask(selectedItem){
		this.displayLoader = true;
		this.$store.dispatch('approveTask',{taskData:this.taskData,selectedItem:selectedItem})
		.then(() => {this.displayLoader = false;
		this.$notify({
						title: "Task approved successfully ",
						icon: "fa fa-check",
						type: "success",
					});})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to update task",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
		}
	},
	mounted() {
		if(this.token==='admin'){
		this.fetchDashboardTaskList()
		this.displayLoader = true;
		this.$store.dispatch('fetchDashboardData')
		.then(() => {
			this.headerCardData[0].content=970 ;
			this.headerCardData[1].content=JSON.stringify(this.$store.state.DashboardData.cardData.total_executives);
			this.displayLoader = false;
			})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch dashboard data ",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
		}
		else{
			this.headerCardData[1].title ='Monthly Duration';
			this.$store.dispatch('fetchExeFarmerCount')
			.then(() => {
				this.headerCardData[0].content=JSON.stringify(this.$store.state.DashboardData.farmerCount) ;
			})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch farmer count ",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})			
			this.$store.dispatch('fetchExecutiveAttendanceList')
			.then(() => {this.displayLoader = false;})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch dashboard attendance ",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
		}
	},
};
</script>
<style></style>
