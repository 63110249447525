<template>
	<div>
		<base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-gradient-success opacity-8"></span>
		</base-header>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">Report {{ actionString }}</h1>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 col-md-12">
							<base-input
								label="Date"
								input-classes="form-control"
								type="date"
								v-model="itemData.date"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<base-input
								label="Name/Tag of report"
								input-classes="form-control"
								v-model="itemData.subject"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label">Section</label>
							<select
								class="rounded m-0 form-control"
								v-model="itemData.section"
								:disabled="viewOnly"
							>
								<option
									v-for="(type, index) in $store.state
										.reportAnalysisData.sectionList"
									:key="index"
									:value="type"
								>
									{{ type.replace(/_/g," ") }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label"
								>Description</label
							>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.details"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="button-bar text-right mt-4">
						<button
							class="btn btn-danger"
							@click.prevent="goBackToParent"
							v-if="!viewOnly"
						>
							Cancel
						</button>
						<button
							class="btn btn-success"
							@click.prevent="performAction"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-report",
	props: {
		farmerID: {
			type: String,
			default: undefined
		},
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			itemData: {
				date: "",
				subject: "",
				section: "Farmer Notice",
				details: "• "
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					reportData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						reportData: this.itemData,
						index: this.editItem.index
					};
				}
				this.$store
					.dispatch("setReportDetails", {
						actionObject: actionObject
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " report",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.date == "") {
				invalidValue = true;
			}if (this.itemData.date.length>10) {
				invalidValue = true;
			}
			if (this.itemData.subject == "") {
				invalidValue = true;
			}
			if (this.itemData.section == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.description;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.description += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		fetchReportDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchReportDetails", {
					reportID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
					this.itemData.data=this.itemData.date.slice(0,10)
				})
				
				.catch(error => {
					this.$notify({
						title: "Failed to fetch recommendation details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},

	computed: {
		tableData() {
			return this.$store.state.reportAnalysisData.tableData;
		},
		currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	mounted() {
		if (this.editItem) {
			this.fetchReportDetails()
			this.itemData = this.currentItem
		}
	}
};
</script>
<style lang="scss" scoped></style>
