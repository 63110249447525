<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center">
					<div class="col-10">
						<h1 class="display-4 m-0">Location</h1>
					</div>
				</div>
			</div>
			<!--Search and Date -->
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col-1">
							<label
								class="form-control-label mt-2 col text-center"
								>Date</label
							>
						</div>
						<div class="col-11">
							<base-input
								type="date"
								class="col-lg-2"
								v-model="date"
								@change="handleChange"
							/>
						</div>
					</div>
				</div>
				<!-- Table -->
				<div class="card shadow border-0 mt-3">
					<GmapMap
						ref="gmap"
						:center="getCenter()"
						:zoom="12"
						style="height: 600px; margin: 0px -8px"
						:options="getOptions()"
					>
						<GmapMarker
							:key="index"
							v-for="(m, index) in getMarkers()"
							:position="m.position"
							:clickable="true"
							:draggable="false"
							:label="m.label"
							label-class=""
							@click="clicked()"
						/>
					</GmapMap>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	props: {
		executiveID: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			displayLoader: false,
			center: {},
			date: "2022-05-11",
		};
	},
	computed: {
		location() {
			return this.$store.state.ExecutiveData.LocationData.locationList;
		},
	},
	methods: {
		fetchLocationsList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchLocationsList", {
					executiveID: this.executiveID,
					date: this.date,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch locations",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
		getOptions() {
			return {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: false,
			};
		},
		clicked() {
			// after click
		},
		getCenter() {
			return {
				lat: this.location[0]?.lat,
				lng: this.location[0]?.lng,
			};
		},
		getMarkers() {
			// generating markers for site map
			var markers = [];
			for (let i = 0; i < this.location?.length; i++) {
				markers.push({
					position: this.location[i],
					label:`${this.location[i]?.date_time?.slice(0,10)}&${this.location[i]?.date_time?.slice(12,19)}`,
				});
			}
			return markers;
		},
		handleChange() {
			if (this.date !== "") {
				if (this.executiveID !== undefined) {
					this.fetchLocationsList();
				}
			}
		},
	},

	mounted() {
		if (this.executiveID !== undefined) {
			this.fetchLocationsList();
		}
	},
};
</script>
<style lang="scss" scoped></style>
