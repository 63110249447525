<template>
	<div>
		<div class="row">
			<div class="col">
				<div class="d-flex justify-content-between mb-4">
					<h2>Pests and Attacks</h2>

					<div
						class="btn border border-success py-2 px-3 mt-2 h-50 text-right"
						@click.prevent="$router.push('pests-and-attacks/add')"
					>
						Add +
					</div>
				</div>

				<base-input
					placeholder="Search pests and attacks"
					class="input-group w-25 mt-1"
					v-model="searchQuery"
					addon-right-icon="fas fa-search"
				></base-input>

				<div class="table-responsive mt-5">
					<base-table
						class="table align-items-center table-flush"
						thead-classes="thead-light"
						tbody-classes="list"
						type="hover"
						:data="pagedTableData"
					>
						<template slot="columns">
							<th>Sl No</th>
							<th>PEST Name</th>
							<th>Attack Name</th>
							<!-- <th
								v-for="(header,
								index) in tableHeadersExcludingDetails"
								:key="index"
							>
								{{ header }}
							</th> -->
							<th>Actions</th>
						</template>

						<template slot-scope="{ row, index }">
							<td>{{ index + 1 }}</td>
							<td>{{ row.pest_name }}</td>
							<td>{{ row.attack_name }}</td>
							<!-- <td
								v-for="(rowItem,
								itemIndex) in rowExcludingDetails(row)"
								:key="itemIndex"
							>
								<div>{{ rowItem }}</div>
							</td> -->
							<td>
								<i
									class="btn fas fa-eye"
									@click.prevent="editData(index, true)"
								></i>
								<i
									class="btn fas fa-pen"
									@click.prevent="editData(index, false)"
								></i>
								<i
									class="btn fas fa-trash text-danger"
									@click.prevent="
										deleteItemIndex = tableData.indexOf(
											pagedTableData[index]
										)
									"
								></i>
							</td>
						</template>
					</base-table>
				</div>
				<base-pagination
					class="mt-3 mr-3"
					:perPage="pageSize"
					:total="tableData.length"
					v-model="currentPage"
					align="center"
				></base-pagination>
			</div>

			<delete-dialog
				v-model="deleteItemIndex"
				:data="tableData"
				actionString="deletePestAndAttacks"
				@actionComplete="
				displayLoader = false;
				deleteItemIndex = -1;"
				@actionCancelled="deleteItemIndex = -1"
				@performingAction="displayLoader = true"
				@actionFailed="displayLoader = false"
			/>
		</div>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";

export default {
	name: "pests-and-attacks",
	components: {
		DeleteDialog
	},
	props: {
		selectedCrop: Number
	},
	data() {
		return {
			deleteItemIndex: -1,
			searchQuery: "",
			pageSize: 15,
			currentPage: 1
		};
	},
	methods: {
		editData(index, viewOnly) {
			var editItemIndex = this.tableData.indexOf(
				this.pagedTableData[index]
			);
			var dialogData = this.tableData[editItemIndex]
			this.$router.push({
				name: "add pests and attacks",
				params: {
					editItem: {
						data: dialogData,
						index: editItemIndex
					},
					viewOnly
				}
			});
		},
		rowExcludingDetails(row) {
			return row.slice(0, row.length - 3);
		},
		fetchPestsAndAttacksList(crop) {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchPestsAndAttacksList",{crop:crop,searchQuery:this.searchQuery})
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch pests and attacks",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},
	computed: {
		tableData() {
			return this.$store.state.cropData.pestsAndAttacks.tableData;
		},
		tableHeaders() {
			return this.$store.state.cropData.pestsAndAttacks.headers;
		},
		tableHeadersExcludingDetails() {
			return this.tableHeaders.slice(0, this.tableHeaders.length - 3);
		},
		// filteredTableData() {
		// 	var filteredTableData = this.tableData;
		// 	if (this.searchQuery != "") {
		// 		var searchQuery = this.searchQuery.trim().toLowerCase();
		// 		filteredTableData = this.tableData.filter(item =>
		// 			item[0].toLowerCase().includes(searchQuery)
		// 		);
		// 	}

		// 	return filteredTableData;
		// },
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		}
	},
	watch: {
		selectedCrop() {
			this.fetchPestsAndAttacksList(this.selectedCrop);
		},
		searchQuery(){
			this.fetchPestsAndAttacksList(this.selectedCrop);
		}
	},
	mounted() {
		this.$store.dispatch("fetchCropList")	
		.catch(error => {
					this.$notify({
						title: "Failed to fetch experts",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
			if(this.selectedCrop !=undefined){
		this.fetchPestsAndAttacksList(this.selectedCrop);
			}
	}
};
</script>
<style scoped></style>
