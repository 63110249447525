var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.pagedTableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(_vm.pageSize * (_vm.currentPage - 1) + (index + 1)))]),_c('td',[_vm._v(_vm._s(row.start_time && row.start_time.slice(0,10)))]),_c('td',[_vm._v(" "+_vm._s(row.start_time && row.start_time.slice(11,19))+" ")]),_c('td',[_vm._v(_vm._s(row.end_time && row.end_time.slice(11,19)))]),_c('td',[_vm._v(" "+_vm._s(row.duration && row.duration.slice(0,7))+" hrs ")])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index,on:{"click":function($event){return _vm.sortTable(header)}}},[_vm._v(" "+_vm._s(header)+" "),(
													header.toLowerCase() ==
													_vm.sortColumn
												)?_c('div',{staticClass:"float-right"},[_c('i',{class:[
														_vm.ascending
															? 'fas fa-arrow-up'
															: 'fas fa-arrow-down' ]})]):_vm._e()])})],2)],2)],1)])]),_c('base-pagination',{staticClass:"mt-3 mr-3",attrs:{"perPage":_vm.pageSize,"total":_vm.tableData.length,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])]),_c('loader',{attrs:{"displayLoader":_vm.displayLoader}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-10"},[_c('h1',{staticClass:"display-4 m-0"},[_vm._v("Check In / Check Out")])])])])}]

export { render, staticRenderFns }