<template>
<div class="row">
	<div class="col-6 card">
		<div class="card-body table-responsive">
			<div class="d-flex justify-content-center mb-2">
				<span class="h1">History</span>
			</div>
			<table class="table align-items-center">
				<tbody class="list">
					<tr v-for="(item, index) in paginatedHistory" :key="index">
						<th scope="row">
							<span class="name mb-0 text-sm">{{ new Date(item.created_date).toLocaleDateString() }}</span>
						</th>
						<td style="max-width: 250px; overflow: auto;">{{ item.title }}</td>
						<td>
							<div class="btn btn-sm btn-success" @click.prevent="sendNews(item)">
								Unsend
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="card-footer d-flex justify-content-end">
			<base-pagination :perPage="pageSizeHistory" :total="history.length" v-model="currentHistoryPage"></base-pagination>
		</div>
	</div>

	<div class="col-6 card">
		<div class="card-body table-responsive">
			<div class="d-flex justify-content-center mb-2">
				<span class="h1">Upcoming</span>
			</div>
			<table class="table align-items-center">
				<tbody class="list">
					<tr v-for="(item, index) in paginatedUpcoming" :key="index">
						<th scope="row">
							<span class="name mb-0 text-sm">{{ new Date(item.created_date).toLocaleDateString() }}</span>
						</th>
						<td style="max-width: 250px; overflow: auto;">{{ item.title }}</td>
						<td>
							<div class="btn btn-sm btn-success" @click.prevent="sendNews(item)">
								Send
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="card-footer d-flex justify-content-end">
			<base-pagination :perPage="pageSizeUpcoming" :total="upcoming.length" v-model="currentUpcomingPage"></base-pagination>
		</div>
	</div>

	<loader :displayLoader="displayLoader"></loader>
</div>
</template>
<script>
export default {
	name: "news",
	data() {
		return {
			displayLoader: false,
			
			pageSizeHistory: 7,
			pageSizeUpcoming: 7,
			currentHistoryPage: 1,
			currentUpcomingPage: 1,
		};
	},
	methods: {
		sendNews(item) {
			this.displayLoader = true;
			this.$store.dispatch('sendAppNews', { newsItem: item }).then(() => {
				this.fetchNews();
				let title = 'Action completed successfully!';
				this.$notify({
					title: title,
					icon: 'fa fa-check',
					type: 'success',
				});
				this.displayLoader = false;
			}).catch((error) => {
				this.$notify({
					title: "Failed to perform action",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
				this.displayLoader = false;
			});
		},
		fetchNews() {
			this.displayLoader = true;
			this.$store.dispatch('fetchAppNewsList').catch((error) => {
				this.$notify({
					title: "Failed to fetch news",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			}).then(() => this.displayLoader = false);
		},
	},
	computed: {
		history() {
			return this.$store.state.appManagementData.newsData.history;
		},
		upcoming() {
			return this.$store.state.appManagementData.newsData.upcoming;
		},
		paginatedHistory() {
			var start = (this.currentHistoryPage - 1) * this.pageSizeHistory;
			return this.history.slice(start, start + this.pageSizeHistory);
		},
		paginatedUpcoming() {
			var start = (this.currentUpcomingPage - 1) * this.pageSizeUpcoming;
			return this.upcoming.slice(start, start + this.pageSizeUpcoming);
		},
	},
	mounted() {
		this.fetchNews();
	}
};
</script>
<style scoped>
</style>
