<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-10">
            <h1 class="display-4 m-0">Expenditure</h1>
          </div>
          <div class="col-2 text-right">
            <base-button
              type="button"
              class="btn-sm btn-danger"
              @click.prevent="report"
            >
              Report
            </base-button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!--Graphs-->
        <div class="row">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-4 mb-5 mb-xl-0">
                <line-chart-view
                  type="default"
                  :chartData="chartData[0]"
                ></line-chart-view>
              </div>

              <div class="col-xl-4 mb-5 mb-xl-0">
                <line-chart-view
                  type="default"
                  :chartData="chartData[1]"
                ></line-chart-view>
              </div>

              <div class="col-xl-4 mb-5 mb-xl-0">
                <line-chart-view
                  type="default"
                  :chartData="chartData[2]"
                ></line-chart-view>
              </div>
            </div>
          </div>
        </div>
        <div v-for="count in [0, 1]" :key="count">
          <!--Date -->
          <div class="row justify-content-center mt-6">
            <label class="form-control-label mt-2 px-4">Start date</label>
            <base-input type="date" v-model="startDate[count]" />
            <label class="form-control-label mt-2 px-4">End date</label>
            <base-input type="date" v-model="endDate[count]" />
          </div>
          <!-- Table -->
          <div class="mb-3 px-lg-7">
            <div class="card shadow border">
              <div class="col card-header text-center">
                <div class="row">
                  <div class="col">
                    <div class="display-4">
                      {{ tables[count].name }}
                    </div>
                  </div>
                  <div class="position-absolute align-self-center right-0 pr-3">
                    <base-button
                      type="button"
                      class="btn-sm btn-primary"
                      @click.prevent="addTableData(count)"
                    >
                      Add New +
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="table-responsive">
                    <base-table
                      class="table align-items-center table-flush"
                      title=""
                      thead-classes="thead-light"
                      tbody-classes="list"
                      type="hover"
                      :data="pagedTableData(count)"
                    >
                      <template slot="columns">
                        <th>Sl No</th>
                        <th
                          v-for="(header, index) in tables[count].headers"
                          v-on:click="sortTable(count, header)"
                          :key="index"
                        >
                          {{ header }}
                          <div
                            class="float-right"
                            v-if="header.toLowerCase() == sortColumn[count]"
                          >
                            <i
                              v-bind:class="[
                                ascending
                                  ? 'fas fa-arrow-up'
                                  : 'fas fa-arrow-down',
                              ]"
                            ></i>
                          </div>
                        </th>
                        <th>Actions</th>
                      </template>

                      <template slot-scope="{ row, index }">
                        <td>{{ index + 1 }}</td>
                        <td>{{ row.added_date }}</td>
                        <td v-if="count == 0">
                          {{ row.amount }}
                        </td>
                        <td v-else>
                          {{ row.recommendation }}
                        </td>
                        <td v-if="count == 0">
                          {{ row.cost_type }}
                        </td>
                        <td v-else>{{ row.cost }}</td>
                        <td>
                          <i
                            class="btn fas fa-eye"
                            @click.prevent="editData(row, count, true)"
                          ></i>
                          <i
                            class="btn fas fa-pen"
                            @click.prevent="editData(row, count, false)"
                          ></i>
                          <!-- It doesn't display the dialog without `tables[count].data = tables[count].data.slice()` -->
                          <i
                            class="btn fas fa-trash text-danger"
                            @click.prevent="deleteItem(count,index)"></i>
                        </td>
                      </template>
                    </base-table>
                  </div>
                </div>
              </div>

              <base-pagination
                class="mt-3 mr-3"
                :perPage="pageSize[count]"
                :total="pagedTableData(count).length"
                v-model="currentPage[count]"
                align="center"
              ></base-pagination>

              <!-- It doesn't update close the dialog without `tables[count].data = tables[count].data.slice()` -->
              <delete-dialog
                v-model="deleteItemIndex[count]"
                :data="tables[count].data"
                :miscData="{farmerID}"
                :actionString="deleteData"
                @actionComplete="deleteItemIndex[count] = -1"
                @actionCancelled="
                  deleteItemIndex[count] = -1;
                  tables[count].data = tables[count].data.slice();"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader :displayLoader="displayLoader"></loader>
  </div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
  name: "Expenditure",
  components: {
    DeleteDialog,
  },
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    selectedLandID: {
      type: Number
    },
    selectedCrop:{
      type:Number
    }
  },
  data() {
    return {
      displayLoader:false,
      ascending: [false, false],
      sortColumn: ["", ""],
      deleteItemIndex: [-1, -1],
      pageSize: [15, 15],
      currentPage: [1, 1],
      startDate: [null ,null],
      endDate: [null, null],
      deleteData:''
    };
  },
  methods: {
    sortTable: function sortTable(index, header) {
      var col = header.toLowerCase();
      if (this.sortColumn[index] === col) {
        this.ascending[index] = !this.ascending[index];
      } else {
        this.ascending[index] = true;
        this.sortColumn[index] = col;
      }
      var ascending = this.ascending[index];
      this.tables[index].data.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },

    addTableData(index) {
      if (index == 0) {
        this.$router.push(
          "/farmers-section/" +
            this.farmerID +
            "/dashboard/expenditure/add-income-and-expenses"
        );
      } else {
        this.$router.push(
          "/farmers-section/" +
            this.farmerID +
            "/dashboard/expenditure/add-cost-optimization"
        );
      }
    },
    editData(row, index, viewOnly) {
      var editItemIndex = this.tables[index].data.indexOf(row);
      var itemData = Object.assign({}, this.tables[index].data[editItemIndex]);
      this.$router.push({
        name: "edit " + this.tables[index].name.toLowerCase(),
        params: {
          editItem: {
            data: itemData,
            index: editItemIndex,
          },
          viewOnly,
        },
      });
    },
    // filteredTableData(index) {
    //   var filteredTableData = this.tables[index].data;
    //   if (this.startDate[index] != "" && this.endDate[index] != "") {
    //     filteredTableData = filteredTableData.filter(
    //       (item) =>{
    //         item.added_date >= this.startDate[index] ||
    //         item.added_date <= this.endDate[index]
    //       }
    //     );
    //   }

    //   return filteredTableData;
    // },
    pagedTableData(index) {
      var start = (this.currentPage[index] - 1) * this.pageSize[index];
      return this.tables[index].data.slice(
        start,
        start + this.pageSize[index]
      );
    },
    report() {
      this.$router.push(
        "/farmers-section/" + this.farmerID + "/dashboard/expenditure/report"
      );
    },
    fetchIncomeAndExpensesList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchIncomeAndExpensesList",{farmerID:this.farmerID,land:this.selectedLandID,startDate:this.startDate[0],endDate:this.endDate[0]})
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch income and expense data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
    fetchCostOptimizeList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchCostOptimizeList",{farmerID:this.farmerID,startDate:this.startDate[1],endDate:this.endDate[1],crop:this.selectedCrop,land:this.selectedLandID})
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch cost data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
    deleteItem(count ,index){
      this.deleteItemIndex[count] = this.tables[count].data.indexOf(this.pagedTableData(count)[index]);this.tables[count].data = this.tables[count].data.slice();
      if(count === 0){
        this.deleteData='deleteIncomeAndExpense'
      }else{
        this.deleteData='deleteCostOptimize'
      }
    }
  },
  computed: {
    tables() {
      return this.$store.state.farmerData.expenditureData.tables
    },
    chartData() {
      return this.$store.state.farmerData.expenditureData.chartData
    },
  },
  watch:{
    selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
        if(this.selectedCrop !=undefined){
          this.fetchIncomeAndExpensesList()
          this.fetchCostOptimizeList();}

		},
    selectedCrop(){
    this.fetchIncomeAndExpensesList()
    this.fetchCostOptimizeList();
    },
    startDate(){
      if(this.startDate[0]){
         this.fetchIncomeAndExpensesList()
      }else{
        this.fetchCostOptimizeList()
      }    
    },
    endDate(){
      if(this.endDate[0]){
         this.fetchIncomeAndExpensesList()
      }else{
        this.fetchCostOptimizeList()
      }

    }
  },
  mounted() {
    this.$store.dispatch("fetchFarmerland",this.farmerID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch Land",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
				if(this.selectedLandID !=undefined){
					this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
					.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		}
		if(this.selectedCrop !=undefined){
		this.fetchIncomeAndExpensesList()
    this.fetchCostOptimizeList();
		}
  },
};
</script>
<style lang="scss" scoped></style>
