<template>
	<div>
		<div class="card mt--7">
			<div class="container-fluid align-items-center">
				<div class="row mt-5">
					<div class="col">
						<h1 class="display-3">Executive Dashboard</h1>
					</div>
				</div>

				<!-- Header card -->
				<div class="row mt-5">
					<div
						v-for="(headerCard, index) in headerCardData"
						class="col-sm-12 col-md-12 col-lg-4"
						:key="index"
					>
						<header-card
							class="m-4"
							:headerCardData="headerCard"
						></header-card>
					</div>
				</div>

				<!--Selectors -->
				<!-- <div class="row">
					<div
						v-for="(selector, index) in selectors"
						:key="index"
						class="col-lg-4 mt-3 px-5"
					>
						<label class="form-control-label">{{
							selector.heading
						}}</label>
						<select class="rounded m-0 form-control">
							<option
								v-for="(type, index) in selector.dataStore"
								:key="index"
							>
								{{ type }}
							</option>
						</select>
					</div>
				</div> -->

				<!--Search and Add  -->
				<div class="row mt-5 px-lg-5">
					<div
						class="
							col-lg-4
							d-flex
							align-items-center
							justify-content-between
						"
					>
						<base-input
							placeholder="Search"
							class="input-group-alternative"
							v-model="searchQuery"
							addon-right-icon="fas fa-search"
						>
						</base-input>
					</div>
					<div class="col mr-4 text-right">
						<base-button
							type="button"
							class="btn btn-primary"
							@click.prevent="addExecutive"
						>
							Add Executive
						</base-button>
					</div>
				</div>
			</div>

			<!-- Table -->
			<div class="my-3 px-lg-7">
				<div class="card shadow">
					<div class="card-header border-0 text-center">
						<h2 class="mb-0">Executive List</h2>
					</div>
					<div class="row">
						<div class="col">
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="pagedExecutiveList"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th>Name</th>
										<th>Actions</th>
									</template>

									<template slot-scope="{ row, index }">
										<td>{{pageSize*(currentPage-1) + (index + 1) }}</td>
										<td>{{ row.name }}</td>
										<td>
											<i
												class="btn fas fa-eye"
												@click.prevent="
													goToExecutiveList(row.id)
												"
											></i>
											<i
												class="
													btn
													fas
													fa-trash
													text-danger
												"
												@click.prevent="
													deleteItemIndex = executiveList.indexOf(pagedExecutiveList[index])
												"
											></i>
										</td>
									</template>
								</base-table>
							</div>
						</div>
					</div>
					<base-pagination
						class="mt-3 mr-3"
						:perPage="pageSize"
						:total="executiveList.length"
						v-model="currentPage"
						align="center"
					></base-pagination>
					<delete-dialog
						v-model="deleteItemIndex"
						:data="executiveList"
						item="executive"
						@performingAction="displayLoader = true"
						@actionComplete="displayLoader = false"
						@actionFailed="displayLoader = false"
						actionString="deleteExecutive"
					>
						<span slot="warning">All data associated with this executive will get deleted too</span>
					</delete-dialog>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";

export default {
	name: "farmer-dashboard",
	components: {
		DeleteDialog,
	},
	props: {},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			deleteItemIndex: -1,
			searchQuery: "",
			pageSize: 15,
			currentPage: 1,
			displayLoader: false,
			headerCardData:[
				{   title: "Executive",
					content: "0",
					icon: "fa fa-user",
					iconGradient: "gradient-blue",
					changePercent: "0%",
					changePositive: true}
					],
			// selectors: [
			// 	{
			// 		heading: "Crop",
			// 		dataStore: this.$store.state.cropData.cropList,
			// 	},
			// 	{
			// 		heading: "Variety",
			// 		dataStore: this.$store.state.farmerData.varieties,
			// 	},
			// 	{
			// 		heading: "Locality",
			// 		dataStore: this.$store.state.farmerData.locality,
			// 	},
			// 	{
			// 		heading: "Subscription Plan",
			// 		dataStore: this.$store.state.farmerData.subscription,
			// 	},
			// 	{
			// 		heading: "Grade",
			// 		dataStore: this.$store.state.farmerData.grade,
			// 	},
			// 	{
			// 		heading: "Executive",
			// 		dataStore: this.$store.state.farmerData.executive,
			// 	},
			// ],
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.executiveList.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		goToExecutiveList(executiveID) {
			this.$router.push("/executive-section/" + executiveID + "/dashboard");
		},
		addExecutive() {
			this.$router.push("/executive-section/add-executive");
		},
		fetchExecutiveList(){
			this.displayLoader = true;
			this.$store.dispatch('fetchExecutiveList').then(() => {
				this.displayLoader = false;
			}).catch((error) => {
				this.displayLoader = false;
				this.$notify({
					title: "Failed to fetch executive list",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			});

		},
		
	},
	computed: {
		executiveList() {
			return this.$store.state.ExecutiveData.executiveList;
		},
		filteredExecutiveList() {
			var filteredExecutiveList = this.executiveList;
			if (this.searchQuery !== "") {
				filteredExecutiveList = this.executiveList.filter((item) =>
					item.name.includes(this.searchQuery)
				);
			}
			return filteredExecutiveList;
		},
		pagedExecutiveList() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.filteredExecutiveList.slice(start, start + this.pageSize);
		},
	},
	mounted() {	
		this.displayLoader = true;
		this.$store.dispatch('fetchDashboardData')
		.then(() => {
			this.headerCardData[0].content=JSON.stringify(this.$store.state.DashboardData.cardData.total_executives);
			this.displayLoader = false;
			})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch dashboard data ",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
			this.fetchExecutiveList()			
	},
};
</script>
<style scoped></style>
