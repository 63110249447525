<template>
<div>
	<base-header class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center">
		<!-- Mask -->
		<span class="mask bg-gradient-success opacity-8"></span>
		<!-- Header container -->
		<div class="container-fluid align-items-center">
			<div class="row">
				<div class="col">
					<h1 class="display-2 text-white">Users</h1>
					<p class="text-white mt-0 mb-0">See all registered users here</p>
					<ul class="nav mt-4">
						<li class="nav-item">
							<span class="">Filter:</span>
						</li>
						<li class="nav-item">
							<a class="text-white p-2" href="javascript:void(0)" @click.prevent="filterUserType = 'All'">All</a>
						</li>
						<li v-for="(userType, index) in userTypes" :key="index" class="nav-item">
							|<a class="text-white p-2" href="javascript:void(0)" @click.prevent="filterUserType = userType">{{ userType }}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col">
					<base-input placeholder="Search Users" class="input-group-alternative" alternative="" v-model="userSearch" addon-right-icon="fas fa-search"></base-input>
				</div>
				<div class="col">
					<button class="btn btn-primary" @click.prevent="$router.push('/users/adduser')">Add user</button>
				</div>
				<div class="col d-flex justify-content-end">
					<base-pagination :perPage="pageDataCount" :total="filteredUserList.length" v-model="currentPage"></base-pagination>
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt--5">
		<div class="row">
			<div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-5" v-for="(user, index) in paginatedData" :key="index">
				<div class="card card-profile shadow">
					<img :src="user.profilePicture" height="100" width="100" class="mx-auto mt-2 rounded-circle">
					<div class="card-body pt-2">
						<div class="text-center">
							<h3> {{ user.name }} </h3>
							<div class="font-weight-light"> {{ user.userType }} </div>
							<base-button size="sm" type="info" class="mt-3" @click.prevent="toggleUserInfoDialog(user)">Details</base-button>
							<base-button size="sm" type="info" class="mt-3" :class="[ user.isBlocked ? 'btn-warning' : 'btn-danger' ]"
								@click.prevent="blockUser = user">{{ user.isBlocked ? 'Unblock' : 'Block' }}</base-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<block-dialog :blockUser="blockUser"
			@performingAction="displayLoader = true"
			@actionComplete="displayLoader = false; blockUser = undefined;"
			@actionFailed="displayLoader = false"
			@actionCancelled="blockUser = undefined"></block-dialog>

		<delete-dialog
			v-model="deleteUserIndex"
			:data="userList"
			item="user"
			@performingAction="displayLoader = true"
			@actionComplete="displayLoader = false; selectedUser = undefined"
			@actionFailed="displayLoader = false"
			@actionCancelled="toggleUserInfoDialog(selectedUser)"
			actionString="deleteUser"
		>
			<span slot="warning">All data associated with this user will get deleted as well</span>
		</delete-dialog>

		<reset-password-dialog :resetUser="resetUser"
			@performingAction="displayLoader = true"
			@actionComplete="displayLoader = false; resetUser = undefined;"
			@actionFailed="displayLoader = false"
			@actionCancelled="resetUser = undefined;"></reset-password-dialog>

		<info-dialog :selectedUser="selectedUser"
			:showUserInfoDialog="showUserInfoDialog"
			@actionDelete="toggleDeleteDialog"
			@actionReset="toggleResetDialog"
			@actionEdit="editUser"
			@actionCancelled="toggleUserInfoDialog(undefined)"></info-dialog>

	</div>

	<loader :displayLoader="displayLoader"></loader>
</div>
</template>
<script>
import BlockDialog from './UserDialogs/BlockDialog'
import DeleteDialog from './ItemDeleteDialog'
import InfoDialog from './UserDialogs/InfoDialog'
import ResetPasswordDialog from './UserDialogs/ResetPasswordDialog'

export default {
	name: 'users',
	components: {
		BlockDialog,
		DeleteDialog,
		InfoDialog,
		ResetPasswordDialog,
	},
	data() {
		return {
			displayLoader: false,
			
			showUserInfoDialog: false,
			blockUser: undefined,
			deleteUserIndex: -1,
			resetUser: undefined,
			selectedUser: undefined,
			userSearch: '',
			filterUserType: 'All',
			currentPage: 1,
			pageDataCount: 16, // Number of entries per page
		}
	},
	methods: {
		toggleDeleteDialog() {
			this.toggleUserInfoDialog(this.selectedUser);
			this.deleteUserIndex = this.userList.findIndex(user => user.id === this.selectedUser.id);
			this.refreshUserList();
		},
		toggleResetDialog() {
			this.toggleUserInfoDialog(this.selectedUser);
			this.resetUser = this.selectedUser;
		},
		toggleUserInfoDialog(user) {
			this.showUserInfoDialog = !this.showUserInfoDialog;
			this.selectedUser = user
			// if(this.selectedUser !=undefined)
			// this.$store.dispatch('fetchUserDetails',this.selectedUser.id).then(() => {
			// 	this.displayLoader = false;
			// }).catch((error) => {
			// 	this.displayLoader = false;
			// 	this.$notify({
			// 		title: "Failed to fetch user list",
			// 		icon: 'fa fa-exclamation-triangle',
			// 		type: 'danger',
			// 		message: error.toString(),
			// 	});
			// });
		},
		editUser() {
			var selectedUser = this.selectedUser;
			this.toggleUserInfoDialog();

			var ctx = this;
			this.$nextTick(() => {
				ctx.$router.push({name: 'add user', params: { editUserData: selectedUser }});
			});
		},
		validateEmail(email) { // Not a good practice but it works with most common cases
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		refreshUserList() {
			this.displayLoader = true;

			this.$store.dispatch('fetchUserList').then(() => {
				this.displayLoader = false;
			}).catch((error) => {
				this.displayLoader = false;
				this.$notify({
					title: "Failed to fetch user list",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			});
		}
	},
	computed: {
		userList() {
			return this.$store.state.userData.userList;
		},
		userTypes() {
			/* return this.$store.state.userData.userTypes.map(userType => 
				userType.split("_").map(part => 
					part[0].toUpperCase() + part.slice(1)).join(' ')); */
			return this.$store.state.userData.userTypes;
		},
		filteredUserList() {
			var filteredUserList = this.userList;
			var filterUserSearch = this.userSearch.toLowerCase();
			var filterUserType = this.filterUserType;

			if (filterUserType !== 'All') {
				filteredUserList = filteredUserList.filter(user => user.userType === filterUserType);
			}

			if (filterUserSearch !== '') {
				filteredUserList = filteredUserList.filter(user => user.name.toLowerCase().includes(filterUserSearch));
			}

			return filteredUserList;
		},
		paginatedData() {
			var start = (this.currentPage - 1) * this.pageDataCount;
			return this.filteredUserList.slice(start, start + this.pageDataCount)
		},
	},
	mounted() {
		if (this.userList.length === 0) {
			this.refreshUserList();
		}
	}
};
</script>
<style>
</style>
