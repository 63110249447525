var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-2 text-right"},[_c('base-button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addTask()}}},[_vm._v(" Add + ")])],1)])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.pagedTableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(_vm.pageSize * (_vm.currentPage - 1) + (index + 1)))]),_c('td',[_vm._v(_vm._s(row.task_date))]),_c('td',[_vm._v(" "+_vm._s(row.task_name)+" ")]),_c('td',[_vm._v(_vm._s(row.is_completed&&row.is_completed===true?'Completed':'Incomplete'))]),_c('td',[_c('i',{staticClass:"btn fas fa-pen",on:{"click":function($event){$event.preventDefault();return _vm.editData(row.id)}}}),_c('i',{staticClass:"btn fas fa-trash text-danger",on:{"click":function($event){$event.preventDefault();_vm.deleteItemIndex =
															_vm.tableData.indexOf(
																_vm.pagedTableData[
																	index
																])}}})])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index,on:{"click":function($event){return _vm.sortTable(header)}}},[_vm._v(" "+_vm._s(header)+" "),(
														header.toLowerCase() ==
														_vm.sortColumn
													)?_c('div',{staticClass:"float-right"},[_c('i',{class:[
															_vm.ascending
																? 'fas fa-arrow-up'
																: 'fas fa-arrow-down' ]})]):_vm._e()])}),_c('th',[_vm._v("Actions")])],2)],2)],1)])]),_c('base-pagination',{staticClass:"mt-3 mr-3",attrs:{"perPage":_vm.pageSize,"total":_vm.tableData.length,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('delete-dialog',{attrs:{"data":_vm.tableData,"actionString":"deleteTask","item":"task","miscData":{ executiveID: _vm.executiveID }},model:{value:(_vm.deleteItemIndex),callback:function ($$v) {_vm.deleteItemIndex=$$v},expression:"deleteItemIndex"}},[_c('span',{attrs:{"slot":"warning"},slot:"warning"},[_vm._v("This data will be removed!")])])],1),_c('loader',{attrs:{"displayLoader":_vm.displayLoader}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-10"},[_c('h1',{staticClass:"display-4 m-0"},[_vm._v("Tasks")])])}]

export { render, staticRenderFns }