<template>
	<div>
		<div class="container-fluid align-items-center">
			<div class="row mb-4">
				<div class="col">
					<h2 class="display-4">
						{{ productID === undefined ? "Add" : "Edit" }} Product
					</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-9">
					<label for="" class="">Name</label>
					<base-input
						placeholder="Name"
						class="input-group-alternative"
						alternative=""
						v-model="data.name"
					>
					</base-input>
				</div>
				<div class="col-3">
					<label for="" class="">Category</label>
					<select
						placeholder="Category"
						name="category"
						id="category"
						class="form-control"
						v-model="data.category"
					>
						<option value="">Category</option>
						<option
							v-for="(item, idx) in categories"
							:key="idx"
							:value="item.id"
						>
							{{ item.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="row  mx-2" v-if="productID === undefined">
				<label class="form-control-label">Choose Default Product Image</label>
				<input
					class="form-control"
					type="file"
					@change="onFileChange"
				/>
				<p class="text-sm mt-2 ml-2 text-danger"> Dimensions: 16:9 ratio</p>
			</div>
			<div v-else>
				<h3>Images</h3>
				<div class="row mt-2 ml-2">
					<div
						class="card shadow-lg custom-card"
						v-for="(item, idx) in data.images"
						:key="idx"
					>
						<img
							:src="item.image"
							class="custom-image img-fluid"
							alt=""
						/>
						
					<div class=" rounded-lg close " @click.prevent="deleteImage(idx)">
							<i class="fas fa-times text-danger ml-5 pl-3"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-4 row">
				<textarea
					placeholder="Details"
					class="form-control mx-3"
					v-model="data.details"
				></textarea>
			</div>
			<div class="row mt-4">
				<div class="col-3">
					<label for="" class="">Price</label>
					<base-input
						placeholder="Price"
						class="input-group-alternative"
						type="number"
						alternative=""
						v-model="data.price"
					>
					</base-input>
				</div>
				<div class="col-3">
					<label for="" class="">Offer Price</label>
					<base-input
						placeholder="Offer Price"
						class="input-group-alternative"
						type="number"
						alternative=""
						v-model="data.offer_price"
					>
					</base-input>
				</div>
				<div class="col-3">
					<label for="" class="">Quantity</label>
					<base-input
						placeholder="Quantity"
						class="input-group-alternative"
						alternative=""
						type="number"
						v-model="data.product_qty"
					>
					</base-input>
				</div>
				<div class="col-3">
					<label for="" class="">Unit</label>
					<select
						placeholder="Unit"
						name="unit"
						id="unit"
						class="form-control"
						v-model="data.unit"
					>
						<option value="">Choose</option>
						<option value="kg">Kg</option>
						<option value="lr">Ltr</option>
						<option value="count">Numbers</option>
					</select>
				</div>
			</div>
			<div class="d-flex justify-content-end mt-4">
				<button class="btn btn-danger" @click.prevent="$router.back()">
					Cancel
				</button>
				<button class="btn btn-success" @click.prevent="addProduct">
					Save
				</button>
			</div>
			<delete-dialog
			v-if="data.images !=undefined"
				:data="data.images"
				v-model="deleteItemIndex"
				actionString="deleteProductImage"
				@actionComplete="fetchProductDetails()"
				item="Image"
				:miscData="{ productID: productID }"
			>
				<span slot="warning">This image will be removed!</span>
			</delete-dialog>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";export default {
	components: {
		DeleteDialog,
	},
	data() {
		return {
			displayLoader: false,
			deleteItemIndex: -1,
			deleteImageData:[],
			images: [],
			files: [],
			imageData: {
				id: null,
				image: "",
			},
			product_id: null,
			data: {
				name: "",
				details: "",
				product_qty: 0,
				price: 0,
				unit: "",
				offer_price: null,
				category: '',
				category_id:null
			},
		};
	},
	computed: {
		categories() {
			return this.$store.state.EcommerceData.Categories.categoryList;
		},
		productID() {
			return this.$route.params.productID;
		},
	},
	methods: {
		deleteImage(index) {
			this.deleteItemIndex = index;
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.imageData.image = this.files[0].name;
		},
		// uploadImageSuccess(formData, index, fileList) {
		// 	console.log(fileList);
		// },
		// beforeRemove(index, done, fileList) {
		// 	console.log("index", index, fileList);
		// 	var r = confirm("remove image");
		// 	if (r == true) {
		// 		done();
		// 	}
		// },
		// editImage(formData, index, fileList) {
		// 	console.log("edit data", formData, index, fileList);
		// },
		addProduct() {
			var error = undefined;
			if (this.data.name == "") {
				error = "Name is required ";
			}
			if (this.data.price == 0) {
				error = "Price is required";
			}
			if (this.data.unit == "") {
				error = "Unit must be selected";
			}
			if (error) {
				this.$notify({
					title: error,
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
			var formData = new FormData();
			formData.append("image", this.files[0]);
			formData.append("id", this.product_id);

			var actionObject = {
				data: this.data,
				productID: this.productID,
			};
			this.$store
				.dispatch("setProductDetails", actionObject)
				.then(() => {
					this.product_id =
						this.$store.state.EcommerceData.Product.product_id;
					if (this.product_id !== null) {
						if (this.files.length != 0) {
							this.uploadProductImage(formData);
						}
					}
					this.$router.back();
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to update product",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
		fetchCategoryList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchCategoryList")
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch category list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		uploadProductImage(formData) {
			this.$store
				.dispatch("uploadProductImage", {
					imageData: formData,
					productID: this.product_id,
				})
				.then(() => {
					this.$store.dispatch("fetchProductList").catch((error) => {
						this.displayLoader = false;
						this.$notify({
							title: "Failed to fetch product list",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to update image",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchProductDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchProductDetails", this.productID)
				.then(() => {
					this.displayLoader = false;
					this.data = JSON.parse(
						JSON.stringify(
							this.$store.state.EcommerceData.Product
								.productDetails
						)
					);
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch product details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
	},
	mounted() {
		this.fetchCategoryList();
		if (this.productID !== undefined) {
			this.fetchProductDetails();
		}
	},
};
</script>
<style scoped>
.custom-card {
	padding: 10px;
	margin: 10px;
	border: 1px solid #cfd2cf;
}
.custom-image {
	height: 10rem;
	width: 10rem;
}
.close {
	width:20px;
	height: 20px;
	cursor: pointer;
	margin-top: 5px;
}
</style>
