<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h1 class="display-4">Disease History</h1>
          </div>
          <div class="col-4 text-right">
            <base-button v-if="token ==='admin'" type="button" class="btn btn-primary" @click.prevent="addDiseaseHistory(farmerID)">
              Add +
            </base-button>
          </div>
        </div>
      </div>

      <!--graphs-->
      <div class="card-body">
        <!-- Table -->
        <div class="my-3">
          <div class="card shadow">
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <base-table
                    class="table align-items-center table-flush"
                    title=""
                    thead-classes="thead-light"
                    tbody-classes="list"
                    type="hover"
                    :data="pagedTableData"
                  >
                    <template slot="columns">
                      <th>Sl No</th>
                      <th
                        v-for="(header, index) in tableHeaders"
                        v-on:click="sortTable(header)"
                        :key="index"
                      >
                        {{ header }}
                        <div
                          class="float-right"
                          v-if="header.toLowerCase() == sortColumn"
                        >
                          <i
                            v-bind:class="[
                              ascending
                                ? 'fas fa-arrow-up'
                                : 'fas fa-arrow-down',
                            ]"
                          ></i>
                        </div>
                      </th>
                      <th v-if="token ==='admin'">Actions</th>
                    </template>

                    <template slot-scope="{ row, index }">
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.date }}</td>
                      <td>
                        {{ row.disease }}
                      </td>
                      <td>
                        {{ row.precautions.slice(0, 16) + "..." }}
                      </td>
                      <td>{{ row.details.slice(0, 16) + "..." }}</td>
                      <td v-if="token ==='admin'">
                        <i
                          class="btn fas fa-eye"
                          @click.prevent="editData(row, true)"
                        ></i>
                        <i
                          class="btn fas fa-pen"
                          @click.prevent="editData(row, false)"
                        ></i>
                        <i
                          class="btn fas fa-trash text-danger"
                          @click.prevent="
                            deleteItemIndex = tableData.indexOf(
                              pagedTableData[index]
                            )
                          "
                        ></i>
                      </td>
                    </template>
                  </base-table>
                </div>
              </div>
            </div>
            <base-pagination
              class="mt-3 mr-3"
              :perPage="pageSize"
              :total="filteredTableData.length"
              v-model="currentPage"
              align="center"
            ></base-pagination>
            <delete-dialog
              v-model="deleteItemIndex"
              :data="tableData"
             :farmerID="farmerID"
							actionString="deleteDiseaseHistory"
							@actionComplete="
								displayLoader = false;
								deleteItemIndex = -1;
							"
							@actionCancelled="deleteItemIndex = -1"
							@performingAction="displayLoader = true"
							@actionFailed="displayLoader = false"
            />
          </div>
        </div>
      </div>
    </div>
    <loader :displayLoader="displayLoader"></loader>
  </div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
  name: "disease-history",
  components: {
    DeleteDialog,
  },
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    selectedLandID: {
      type: Number,
    },
    selectedCrop: {
      type: Number,
    }
  },
  data() {
    return {
      ascending: false,
      sortColumn: "",
      displayLoader: false,
      deleteItemIndex: -1,
      pageSize: 15,
      currentPage: 1,
    };
  },
  methods: {
    sortTable: function sortTable(header) {
      var col = header.toLowerCase();
      if (this.sortColumn === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = col;
      }
      var ascending = this.ascending;
      this.tableData.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
    addDiseaseHistory() {
      this.$router.push({
        name: "add disease history",
        params: {
          landID: this.selectedLandID,
          crop: this.selectedCrop,
        },
      });
    },
    editData(row, viewOnly) {
      var editItemIndex = this.tableData.indexOf(row);
      var itemData = Object.assign({}, this.tableData[editItemIndex]);
      this.$router.push({
        name: "edit disease history",
        params: {
          editItem: {
            data: itemData,
            index: editItemIndex,
          },
          landID: this.selectedLandID,
          crop: this.selectedCrop,
          viewOnly,
        },
      });
    },
    fetchDiseaseList(){
      this.displayLoader = true;
			this.$store
				.dispatch("fetchDiseasesList", {crop:this.selectedCrop,searchQuery:""})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch disease list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
    },
    fetchDiseaseHistory() {
      this.displayLoader = true;
			this.$store
				.dispatch("fetchDiseaseHistoryList", { farmerID: this.farmerID, landID: this.selectedLandID,crop:this.selectedCrop })
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch disease history",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
    }
  },
  computed: {
    diseaseHistoryData() {
      return this.$store.state.farmerData.diseaseHistoryData;
    },
    tableHeaders() {
      return this.diseaseHistoryData.tableHeaders;
    },
    tableData() {
      return this.diseaseHistoryData.tableData;
    },
    filteredTableData() {
      var filteredTableData = this.tableData;
      return filteredTableData;
    },
    pagedTableData() {
      var start = (this.currentPage - 1) * this.pageSize;
      return this.filteredTableData.slice(start, start + this.pageSize);
    },
    token(){
			return localStorage.getItem('decoded-token');
		}
  },
  mounted() {
   
		this.$store.dispatch("fetchFarmerland",this.farmerID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch Land",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});
				if(this.selectedLandID !=undefined){
					this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
					.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		}
		if(this.selectedCrop !=undefined){
		this.fetchDiseaseHistory();
     this.fetchDiseaseList();
		}
	},
	watch: {
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
		},
		selectedCrop(){
			this.fetchDiseaseHistory();
     this.fetchDiseaseList();

		}
	}
};
</script>
<style scoped></style>